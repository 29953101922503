import React, { useEffect, useState } from "react";
import "../assets/scss/main/main.css";
import "react-multi-carousel/lib/styles.css";

import { Footer } from "../components/Footer";

import { Nav } from "../components/Nav";
import { Hero } from "../components/home/Hero";
import { Category } from "../components/home/Category";
import { BestDeal } from "../components/home/BestDeal";
import { News } from "../components/home/News";
import { Brand } from "../components/home/Brand";
import { BestOffers } from "../components/home/BestOffers";
import { get_HomeData } from "../actions";
import YoutubeEmbed from "../components/home/YoutubeEmbed";
import Analytics from "../helpers/Analytics";


export function Home() {
  const [isLoading, setLoading] = useState(true);
  const [slideShow, setSlideShow] = useState([])
  const [bestSeller, setBestSeller] = useState([])
  const [bestDeal, setBestDeal] = useState([])
  const [bestOffers, setBestOffers] = useState([])
  const [brandList, setBrandLits] = useState([])
  const [blogList, setBlogList] = useState([])
  const [homeData,setHomeData] = useState({
    home_pagetext: '',
    link_video: '',
    home_pageimage: '',
  })

  const getHomeData = () => {
    setLoading(true);
    get_HomeData((res) => {
      const {slide_show, best_seller, promo, best_offers, brand, blog, setting_home} = res;
      setSlideShow(slide_show);
      setBestSeller(best_seller)
      setBestDeal(promo);
      setBestOffers(best_offers);
      setBrandLits(brand);
      setBlogList(blog);
      setHomeData(setting_home);

      setLoading(false)
    });
  }

  useEffect(() => {
    getHomeData();
  }, [])

  return (
    <>
      <Analytics title={'Home - drmo store'}></Analytics>
      <Nav active="home"></Nav>
      <Hero list={slideShow} loading={isLoading}></Hero>
      <Category list={bestSeller} loading={isLoading}></Category>
      <BestDeal list={bestDeal} loading={isLoading}></BestDeal>
      <BestOffers list={bestOffers} loading={isLoading}></BestOffers>
      <Brand list={brandList} loading={isLoading}></Brand>
      <News list={blogList} loading={isLoading}></News>
      <YoutubeEmbed embedId="121" isLoading={isLoading} data={homeData}></YoutubeEmbed>
      <Footer></Footer>
    </>
  );
}
