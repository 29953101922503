import React, { useEffect } from "react";
import imgSideshow1 from "../../assets/img/img-slideshow-1.png";
import imgSideshow2 from "../../assets/img/img-slideshow-2.png";
import imgSideshow3 from "../../assets/img/img-slideshow-3.png";
import "../../assets/scss/home/home.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function Hero({list, loading}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {

      breakpoint: { max: 1024, min: 767 },
      items: 1,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="">
        <div className="hero">
          {loading ? 
            <Skeleton width={'100%'} height={'100vh'}></Skeleton>
          : <Carousel
          responsive={responsive}
          showDots={true}
          arrows={false}
          draggable
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite
          focusOnSelect={false}>
          {list.map((value, index) => (
          <div className="" key={`slideshow-${index}`}>
          <div className="wrapper-text">
            <div className="title-hero">
              {value.title}
            </div>
            <div className="info-hero">
              {value.subtitle}
            </div>
            <div className="mt-4">
              <Link to="/contact-us" className="btn-white" style={{ borderColor: 'white' }}>Hubungi Kami</Link>
            </div>
          </div>
          <img
            src={imageUrl.slide_show(value.gambar)}
            alt={imageUrl.slide_show(value.gambar)}
            className="img-slideshow"></img>
        </div>
          ))}
          {/* <div className="">
            <div className="wrapper-text">
              <div className="title-hero">
                Jadikan Setiap Proyek Multimedia Anda <br></br> Luar Biasa dengan
                DrmoStore.
              </div>
              <div className="info-hero">
                Dari kamera hingga sistem audio, kami memiliki semua yang Anda{" "}
                <br></br> butuhkan untuk proyek multimedia Anda.
              </div>
              <div className="mt-4">
                <Link className="btn-white">Hubungi Kami</Link>
              </div>
            </div>
            <img
              src={imgSideshow2}
              alt={imgSideshow2}
              className="img-slideshow"></img>
          </div>
          <div className="">
            <div className="wrapper-text">
              <div className="title-hero">
                Jadikan Setiap Proyek Multimedia Anda <br></br> Luar Biasa dengan
                DrmoStore.
              </div>
              <div className="info-hero">
                Dari kamera hingga sistem audio, kami memiliki semua yang Anda{" "}
                <br></br> butuhkan untuk proyek multimedia Anda.
              </div>
              <div className="mt-4">
                <Link className="btn-white">Hubungi Kami</Link>
              </div>
            </div>
            <img
              src={imgSideshow3}
              alt={imgSideshow3}
              className="img-slideshow"></img>
          </div> */}
        </Carousel>}
        </div>
      </div>
    </>
  );
}
