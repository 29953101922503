import { getAssetEndpoint } from "../helpers";

var _url = getAssetEndpoint();
var _api = process.env.REACT_APP_API_URL;

const imageUrl = {
    blog: (string) => {
        return `${_url}/blog/${string}`
    },
    slide_show: (string) => {
        return `${_url}/slide_show/${string}`
    },
    product: (string) => {
        return `${_url}/product/${string}`
    },
    product_category: (string) => {
        return `${_url}/product_category/${string}`
    },
    product_brand: (string) => {
        return `${_url}/product_brand/${string}`
    },
    product_varian: (string) => {
        return `${_url}/product_varian/${string}`
    },
}


export {
    imageUrl
}