import React, { useState } from "react";
import imgProduct2 from "../../assets/img/img-product-2.png";
import { useMediaQuery } from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import lens from "../../assets/img/lens.png";
import lighting from "../../assets/img/lighting.png";
import drone from "../../assets/img/drone.png";
import camera from "../../assets/img/camera.png";
import audio from "../../assets/img/audio.png";
import aksesoris from "../../assets/img/aksesoris.png";
import stabillizer from "../../assets/img/stabillizer.png";
import CardProduct from "../../components/element/CardProduct/CardProduct";

// import iconCheckout from "../../assets/img/icon/icon-checkout.svg";
// import iconCheckout from "../../assets/img/icon/icon-checkout.svg";

import "../../assets/scss/home/home.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SekeletonCardProduct from "../element/CardProduct/CardProductSekeleton";
import { imageUrl } from "../../actions/image";

export function BestOffers({ list, loading }) {
  const [indexActive, setIndexActive] = useState(0);
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 1.5,
    },
  };
  const responsiveCategory = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2.5,
    },
  };
  return (
    <>
      <div className="container">
        <div className="" id="best-offers">
          <div className="d-flex justify-content-center align-items-center">
            <div className="title-section">Today Best Offers</div>
            {/* <div className="see-all">See All</div> */}
          </div>

          <div className="row">
            <div className="col-12">
              {isDesktopScreen && (
                <>
                  {loading ?
                    <div className="list-category">
                      {['', '', '', '', ''].map((e, i) => (
                        <div className="item-category" key={`skeletonCategoryBestOffer${i}`}>
                          <Skeleton width={50} height={50} borderRadius={5}></Skeleton>
                          <Skeleton width={130}></Skeleton>
                        </div>
                      ))}
                    </div>
                    : <div className="list-category">
                        {list.map((value, index) => (
                          <div className={`item-category ${indexActive === index ? 'active' : ''}`} key={`category-bestoffer1${index}`} onClick={(e) => setIndexActive(index)}>
                            <img src={imageUrl.product_category(value.gambar)} alt={imageUrl.product_category(value.gambar)}></img>
                            <div className="title">{value.nama}</div>
                          </div>
                        ))}
                    </div>}
                </>
              )}
              {isPhoneScreen && (
                <>
                  <div className="mt-4">
                    {loading ?
                      <Carousel
                        responsive={responsiveCategory}
                        showDots={false}
                        arrows={false}
                        draggable
                        focusOnSelect={false}>
                        {['', '', '', '', ''].map((e, i) => (
                          <div className="item-category" key={`skeletonCategoryBestOffer${i}`}>
                            <Skeleton width={50} height={50} borderRadius={5}></Skeleton>
                            <Skeleton width={130}></Skeleton>
                          </div>
                        ))}
                      </Carousel>
                      :
                      <Carousel
                        responsive={responsiveCategory}
                        showDots={false}
                        arrows={false}
                        draggable
                        focusOnSelect={false}>
                        {list.map((value, index) => (
                          <div className={`item-category ${indexActive === index ? 'active' : ''}`} key={`category-bestoffer${index}`} onClick={(e) => setIndexActive(index)}>
                            <img src={imageUrl.product_category(value.gambar)} alt={imageUrl.product_category(value.gambar)}></img>
                            <div className="title">{value.nama}</div>
                          </div>
                        ))}
                      </Carousel>}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="row align-items-center mt-4">
            <div className="col-12">
              <div className="list-item ">
                {loading ?
                  <>
                    <Carousel
                      responsive={responsive}
                      showDots={false}
                      arrows={false}
                      draggable
                      infinite
                      focusOnSelect={false}>
                      {['', '', '', '', ''].map((e, i) => (
                        <SekeletonCardProduct key={`best-offers${i}`}></SekeletonCardProduct>
                      ))}
                    </Carousel>
                  </>
                  :
                  <>
                    {list.length > 0 ?
                      <Carousel
                        responsive={responsive}
                        showDots={false}
                        arrows={true}
                        draggable={false}
                        infinite={true}
                        focusOnSelect={false}>
                        {list[indexActive].list.map((value, index) => (
                          <CardProduct
                            key={`bestoffers${index}`}
                            discount={value.satuan_promo}
                            category={value.category}
                            name={value.nama}
                            image={imageUrl.product(value.gambar)}
                            url={value.url}
                            rating={value.rating}
                            review={value.total_review}
                            originalPrice={value.harga}
                            discountPrice={value.harga_promo}></CardProduct>
                        ))}
                      </Carousel>
                      : ''}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
