import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
export const NavAbouts = () => {
  const location = useLocation();
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const responsiveNav = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: true,
      breakpoint: { max: 767, min: 0 },
      items: 2.5,
    },
  };
  return (
    <>
      {isDesktopScreen && (
        <div className="nav-information">
          <Link to="/about-us">
            <div
              className={`item-nav ${
                location.pathname === "/about-us" ? "active" : ""
              }`}>
              About Us
            </div>
          </Link>
          <Link to="/privacy-policy">
            <div
              className={`item-nav ${
                location.pathname === "/privacy-policy" ? "active" : ""
              }`}>
              Privacy Policy
            </div>
          </Link>
          <Link to="/terms-conditions">
            <div
              className={`item-nav ${
                location.pathname === "/terms-conditions" ? "active" : ""
              }`}>
              Terms and Conditions
            </div>
          </Link>
          <Link to="/warranty">
            <div
              className={`item-nav ${
                location.pathname === "/warranty" ? "active" : ""
              }`}>
              Warranty
            </div>
          </Link>
          <Link to="/contact-us">
            <div
              className={`item-nav ${
                location.pathname === "/contact-us" ? "active" : ""
              }`}>
              Contact Us
            </div>
          </Link>
        </div>
      )}

      {isPhoneScreen && (
        <>
          <div className="nav-information d-flex">
            {/* <Carousel
              responsive={responsiveNav}
              showDots={false}
              arrows={false}
              autoPlay={false}
              draggable> */}
            <Link to="/about-us">
              <div
                className={`item-nav ${
                  location.pathname === "/about-us" ? "active" : ""
                }`}>
                About Us
              </div>
            </Link>
            <Link to="/privacy-policy">
              <div
                className={`item-nav ${
                  location.pathname === "/privacy-policy" ? "active" : ""
                }`}>
                Privacy Policy
              </div>
            </Link>
            <Link to="/terms-conditions">
              <div
                className={`terms-condition item-nav ${
                  location.pathname === "/terms-conditions" ? "active" : ""
                }`}>
                Terms and Conditions
              </div>
            </Link>
            <Link to="/warranty">
              <div
                className={`item-nav ${
                  location.pathname === "/warranty" ? "active" : ""
                }`}>
                Warranty
              </div>
            </Link>
            <Link to="/contact-us">
              <div
                className={`item-nav ${
                  location.pathname === "/contact-us" ? "active" : ""
                }`}>
                Contact Us
              </div>
            </Link>
            {/* </Carousel> */}
          </div>
        </>
      )}
    </>
  );
};
