import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/blog/blog.css";
import CardBlog from "../components/element/CardBlog";
import news4 from "../assets/img/news-4.png";
import Skeleton from "react-loading-skeleton";
import { get_BlogDetail } from "../actions";
import { convertIsi, formatDate } from "../helpers";
import { imageUrl } from "../actions/image";
import MetaTags from "../helpers/MetaTags";
import Analytics from "../helpers/Analytics";

export function DetailBlog() {
  const params = useParams();
  const [loadPage, setLoadPage] = useState(true);
  const [contentBlog, setContentBlog] = useState({
    url: '',
    judul: '',
    kategori: '',
    created_at: '',
    isi: '',
    gambar: '',
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  const [recentPostList, setRecentPost] = useState([]);

  const getContent = () => {
    setLoadPage(true);
    get_BlogDetail(params.url, (res) => {
      const { data, recentPost } = res;
      setContentBlog({ ...contentBlog, ...data });
      setRecentPost(recentPost);
      setLoadPage(false);
    });
  }
  useEffect(() => {
    getContent();

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
  }, []);
  return (
    <>
      <MetaTags title={contentBlog.judul ? `${contentBlog.judul} - drmo store` : 'drmo store'} url={window.location.href} description={contentBlog.isi ? convertIsi(contentBlog.isi) : metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name} date={contentBlog.created_at} image={imageUrl.blog(contentBlog.gambar)}></MetaTags>
      <Nav active="home"></Nav>
      {contentBlog.judul && (<Analytics title={ `${contentBlog.judul} - drmo store`}></Analytics>)}
      <div className="container">
        <div className="section" id="detail-blog">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/blog">Blog</Link>
              </li>
              {!loadPage ? <>
                <li class="breadcrumb-item active" aria-current="page">
                  {contentBlog.judul}
                </li>
              </> : ''}
            </ol>
          </nav>

          <div className="row mt-5">
            <div className="col-md-8 col-12 mb-5">
              <div className="d-flex align-items-end justify-content-between mb-4 flex-wrap flex-md-nowrap">
                <div className="">
                  <div className="title-section">
                    {loadPage ? <Skeleton width={350} height={50}></Skeleton> : contentBlog.judul}
                  </div>
                  <div className="category">{loadPage ? <Skeleton width={100} height={20}></Skeleton> : contentBlog.kategori}</div>
                </div>
                <div className="date">{loadPage ? <Skeleton width={100} height={20}></Skeleton> : formatDate(contentBlog.created_at)}</div>
              </div>
              {loadPage ? <Skeleton width={'100%'} height={300} borderRadius={12}></Skeleton> : <img src={imageUrl.blog(contentBlog.gambar)} alt={imageUrl.blog(contentBlog.gambar)} className="img-blog"></img>}
              {loadPage ? <div className="desc">
                {['', ''].map((value, index) => (
                  <div className="mt-3">
                    <Skeleton width={'100%'} height={'10'} count={5}></Skeleton>
                    <Skeleton width={'40%'} height={'10'}></Skeleton>
                  </div>
                ))}
              </div> : <div className="desc" dangerouslySetInnerHTML={{ __html: contentBlog.isi }}></div>}
            </div>
            <div className="col-md-4 col-12">
              <div className="filter">
                <div className="title-filter">{!loadPage ? 'Recent Post' : ''}</div>
                {loadPage ?
                  ['', '', '', ''].map((e, i) => (
                    <div className="item-filter d-flex align-items-center">
                      <Skeleton width={100} height={100}></Skeleton>
                      {/* <img src={news4} alt={news4} className="img-recent-post"></img> */}
                      <div className="ms-2">
                        <div className="name"><Skeleton width={'100%'} height={25}></Skeleton></div>
                        <div className="category"><Skeleton width={100} height={15}></Skeleton></div>
                        <div className="date"><Skeleton width={150} height={15}></Skeleton></div>
                      </div>
                    </div>
                  ))
                  : recentPostList.map((value, index) => (
                    <Link to={`/blog/${value.url}`} onClick={(e) => getContent()}>
                      <div className="item-filter d-flex align-items-center" key={`recentPost${index}`}>
                        <img src={imageUrl.blog(value.gambar)} alt={imageUrl.blog(value.gambar)} className="img-recent-post"></img>
                        <div className="">
                          <div className="name">{value.judul}</div>
                          <div className="category">{value.kategori}</div>
                          <div className="date">{formatDate(value.created_at)}</div>
                        </div>
                      </div>
                    </Link>
                  ))}
                {/* <div className="item-filter d-flex align-items-center">
                    <img src={news4} alt={news4} className="img-recent-post"></img>
                    <div className="">
                        <div className="name">The Peak Quality of The Viewfinder</div>
                        <div className="category">Camera</div>
                        <div className="date">03 Sept 2023</div>
                    </div>
                </div>
                <div className="item-filter d-flex align-items-center">
                    <img src={news4} alt={news4} className="img-recent-post"></img>
                    <div className="">
                        <div className="name">The Peak Quality of The Viewfinder</div>
                        <div className="category">Camera</div>
                        <div className="date">03 Sept 2023</div>
                    </div>
                </div>
                <div className="item-filter d-flex align-items-center">
                    <img src={news4} alt={news4} className="img-recent-post"></img>
                    <div className="">
                        <div className="name">The Peak Quality of The Viewfinder</div>
                        <div className="category">Camera</div>
                        <div className="date">03 Sept 2023</div>
                    </div>
                </div>
                <div className="item-filter d-flex align-items-center">
                    <img src={news4} alt={news4} className="img-recent-post"></img>
                    <div className="">
                        <div className="name">The Peak Quality of The Viewfinder</div>
                        <div className="category">Camera</div>
                        <div className="date">03 Sept 2023</div>
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
