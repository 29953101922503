import { apiClient } from "../helpers"

const _URL = {
    home: '/home',
    meta: '/meta',
    aboutUs: '/content/about-us',
    contentOther: '/content/{slugId}',
    brand: '/brand',
    category: '/category',
    blog: '/blog',
    blogDetail: '/blog/{slugId}',
    blogCategory: '/blog-category',
    product: '/product',
    productDetail: '/product/{slugId}',
    message: '/save-message',
    promo: '/promo',
}


const get_HomeData = (successCallback, errorCallback) => {
    apiClient(false).get(_URL.home).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_MetaData = (successCallback, errorCallback) => {
    apiClient(false).get(_URL.meta).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_AboutUs = (successCallback, errorCallback) => {
    apiClient(false).get(_URL.aboutUs).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_OtherContent = (slugId, successCallback, errorCallback) => {
    apiClient(false).get(_URL.contentOther.replace('{slugId}', slugId)).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_BrandList = (params, successCallback, errorCallback) => {
    apiClient(false).get(_URL.brand, {
        params: params
    }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_CategoryList = (params, successCallback, errorCallback) => {
    apiClient(false).get(_URL.category, {
        params: params
    }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_BlogList = (params, successCallback, errorCallback) => {
    apiClient(false).get(_URL.blog, {
        params: params
    }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_BlogDetail = (slugId, successCallback, errorCallback) => {
    apiClient(false).get(_URL.blogDetail.replace('{slugId}', slugId)).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_BlogCategory = (successCallback, errorCallback) => {
    apiClient(false).get(_URL.blogCategory).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_ProductList = (params, successCallback, errorCallback) => {
    apiClient(false).get(_URL.product, {
        params: params
    }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_ProductDetail = (slugId, parmas, successCallback, errorCallback) => {
    apiClient(false).get(_URL.productDetail.replace('{slugId}', slugId), {
        params: parmas
    }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const post_SaveMessage = (params, successCallback, errorCallback) => {
    apiClient(true).post(_URL.message, params).then((result) => {
        if (result.status === 201 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_PromoList = (params, successCallback, errorCallback) => {
    apiClient(true).get(_URL.promo, params).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}



export {
    get_HomeData,
    get_MetaData,
    get_AboutUs,
    get_BlogCategory,
    get_BlogDetail,
    get_BlogList,
    get_BrandList,
    get_CategoryList,
    get_OtherContent,
    get_ProductDetail,
    get_ProductList,
    post_SaveMessage,
    get_PromoList
}