import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { useMediaQuery } from "react-responsive";
import Carousel from "react-multi-carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ReactImageMagnify from "react-image-magnify";

import "react-accessible-accordion/dist/fancy-example.css";
import "../assets/scss/home/home.css";
import "../assets/scss/detail_product/detail_product.css";
import star from "../assets/img/icon/star.svg";
import starOutline from "../assets/img/icon/star-outline.svg";
import starHalf from "../assets/img/icon/star-half.svg";
import telegram from "../assets/img/icon/icon-telegram-fullcolor.svg";
import twitter from "../assets/img/icon/icon-twitter-fullcolor.svg";
import facebook from "../assets/img/icon/icon-fb-fullcolor.svg";
import instagram from "../assets/img/icon/icon-ig-fullcolor.svg";
import wa from "../assets/img/icon/icon-wa-fullcolor.svg";
import blibli from "../assets/img/icon/icon-blibli.svg";
import shopee from "../assets/img/icon/icon-shopee.svg";
import tokped from "../assets/img/icon/icon-tokped.svg";
import emptyReview from "../assets/img/empty-review.svg";
import review from "../assets/img/img-review.png";
import CardProduct from "../components/element/CardProduct/CardProduct";
import Skeleton from "react-loading-skeleton";
import SekeletonCardProduct from "../components/element/CardProduct/CardProductSekeleton";
import { imageUrl } from "../actions/image";
import { get_ProductDetail } from "../actions";
import { convertIsi, cryptoJs, formatDate, formatMoney } from "../helpers";
import Select from "react-select";
import MetaTags from "../helpers/MetaTags";
import FsLightbox from "fslightbox-react";
import Analytics from "../helpers/Analytics";

export function DetailProduct() {
  const location = useLocation();
  const params = useParams();
  const refDescription = useRef();
  const refSpesification = useRef();
  const refReview = useRef();
  const [image, setImage] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [varianSelected, setVarianSelected] = useState("");
  const [toggler, setToggler] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState('');
  const [detailProduct, setData] = useState({
    sku: "",
    url: "",
    nama: "",
    brand: {
      nama: "",
      url: "",
      gambar: "",
    },
    category: {
      nama: "",
      url: "",
      gambar: "",
    },
    deskripsi: "",
    fitur: "",
    spesifikasi: "",
    harga: "",
    harga_promo: "",
    satuan_promo: "",
    status: "",
    link_tokped: "",
    link_shopee: "",
    link_blibli: "",
    varian_id_promo: "",
    rating: "",
    total_review: "",
    is_best_seller: "",
    warranty: "",
  });

  const checkUrl = (url) => {
    var urlFix = url;
    if (!url.includes("https://") && !url.includes("http://")) {
      urlFix = `https://${url}`;
    } else if (url.includes("http")) {
      urlFix = url.replace("http://", "https://");
    }
    return urlFix;
  };

  const [imageList, setImageList] = useState([]);
  const [varianList, setVarian] = useState([]);
  const [dataReview, setReview] = useState({
    all: [],
    list: [],
    rating: [0, 0, 0, 0, 0],
  });
  const [pagingReview, setPagingReview] = useState({
    page: 1,
    totalPage: 1,
  });
  const [otherProduct, setOtherProduct] = useState([]);
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 4,
    },
    mobile: {
      showDots: true,
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 2.5,
    },
  };
  const responsiveRealated = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.5,
    },
    mobile: {
      showDots: true,
      centerMode: false,
      breakpoint: { max: 767, min: 0 },
      items: 1.5,
    },
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        textAlign: "left",
      };
    },
  };

  const perPageReview = 5;

  const getImage = (index) => {
    var dataImage = imageList.find((e) => e.index == index);
    setImage(imageUrl.product(dataImage.nama_file));
  };

  const getProduct = (url) => {
    const urlSearchParams = new URLSearchParams(location.search);
    var promoId = '';
    if (urlSearchParams.get('_a')) {
      try {
        var decrypt = cryptoJs('decrypt', urlSearchParams.get('_a'));
        var promo = JSON.parse(decrypt);
        promoId = promo.id;
      } catch (error) {
      }
    }
    setPageLoad(true);
    get_ProductDetail(url, {
      promoId: promoId
    }, (res) => {
      const { data, varian, image, review, other } = res;
      setData({ ...data, hasPromo: false });
      setImageList(image);
      const varianSelect = varian.map((value) => {
        return {
          value: value.id,
          label: value.nama,
          harga: value.harga,
          gambar_index: value.gambar_index,
          satuan_promo: value.satuan_promo,
          harga_promo: value.harga_promo,
          status: value.status,
        };
      });
      var varianPromo = varianSelect.find((e) => e.harga_promo);

      if (!varianPromo) {
        varianPromo = varianSelect[0];
      }
      setVarianSelected(varianPromo ?? "");
      setVarian([...varianSelect]);
      setReview({ ...dataReview, all: review.list, rating: review.rating });
      setPagingReview({
        page: 1,
        totalPage: Math.ceil(review.list.length / perPageReview),
      });
      setImage(imageUrl.product(image[0].nama_file));
      setOtherProduct(other);
      setPageLoad(false);
    });
  };

  const checkSatuan = (string) => {
    if (string && string.toString().includes("%")) {
      let numberValue = parseFloat(string) || 0; // Handle non-numeric input
      let formattedPercentage = numberValue.toString() + "%";
      return formattedPercentage;
    }

    return string.replace("Rp", "Rp ");
  };

  const generateStarReview = (rating) => {
    var ratingElement = [];
    var half = rating - Math.floor(rating);
    var ratingBulet = Math.floor(rating);
    var outline = 5 - rating;

    for (let i = 1; i <= ratingBulet; i++) {
      ratingElement.push(<img src={star} alt={star} className="star"></img>);
    }

    if (half > 0) {
      outline -= 1;
      ratingElement.push(
        <img src={starHalf} alt={starHalf} className="star"></img>
      );
    }

    for (let i = 0; i < outline; i++) {
      ratingElement.push(
        <img src={starOutline} alt={starOutline} className="star"></img>
      );
    }

    return ratingElement;
  };

  useEffect(() => {
    getProduct(params.url);
  }, []);

  useEffect(() => {
    if (varianList.length > 0 && varianSelected.value != "") {
      let hasPromo = false;
      var harga = detailProduct.harga;
      var harga_promo = detailProduct.harga_promo;
      var satuan_promo = detailProduct.satuan_promo;
      var status = varianSelected.status;

      if (varianSelected.harga_promo) {
        hasPromo = true;
        harga = varianSelected.harga;
        harga_promo = varianSelected.harga_promo;
        satuan_promo = varianSelected.satuan_promo;
      } else {
        hasPromo = false;
        harga = varianSelected.harga;
        harga_promo = false;
        satuan_promo = ""
        console.log(varianSelected.harga);
      }
      // if (
      //   detailProduct.harga_promo &&
      //   detailProduct.harga_promo &&
      //   !detailProduct.varian_id_promo
      // ) {
      //   hasPromo = true;
      // } else if (varianSelected.harga_promo) {
      //   hasPromo = true;
      //   harga = varianSelected.harga;
      //   harga_promo = varianSelected.harga_promo;
      //   satuan_promo = varianSelected.satuan_promo;
      // } else if (!varianSelected.harga_promo) {
      //   harga = varianSelected.harga;
      //   harga_promo = varianSelected.harga_promo;
      //   satuan_promo = varianSelected.satuan_promo;
      // }

      setData({
        ...detailProduct,
        hasPromo: hasPromo,
        satuan_promo: satuan_promo,
        status: status,
        harga: harga,
        harga_promo: harga_promo,
      });
      if (varianSelected.gambar_index) {
        getImage(varianSelected.gambar_index);
      }
    }
  }, [varianSelected]);

  useEffect(() => {
    var start = (pagingReview.page - 1) * perPageReview;
    var end = pagingReview.page * perPageReview;
    var obj = dataReview.all.slice(start, end);
    // console.log(obj);
    setReview({ ...dataReview, list: obj });
  }, [pagingReview]);

  const zoom = (e) => {
    const zoomer = e.currentTarget;
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    const x = (offsetX / zoomer.offsetWidth) * 100;
    const y = (offsetY / zoomer.offsetHeight) * 100;

    zoomer.style.backgroundPosition = `${x + 10}% ${y + 10}%`;
  };

  useEffect(() => {
    if (accordionOpen != '') {
      setTimeout(() => {
        var element = document.getElementById(accordionOpen);
        if (element) {
          var headerOffset = 80;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          // refDescription.current.scrollIntoView();
          window.scrollTo({
            top: offsetPosition,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }, [accordionOpen])

  return (
    <>
      <MetaTags
        title={
          detailProduct.nama
            ? `${detailProduct.nama} - drmo store`
            : "drmo store"
        }
        description={convertIsi(detailProduct.deskripsi)}
        url={window.location.href}
        image={image}
        page="detail-product"></MetaTags>
      {detailProduct.nama && (<Analytics title={ `${detailProduct.nama} - drmo store`}></Analytics>)}
      <Nav active="product"></Nav>
      <div className="container">
        <div className="section" id="detail-product">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <Link to="/product">Product</Link>
              </li>
              {!pageLoad ? (
                <>
                  <li class="breadcrumb-item active" aria-current="page">
                    {detailProduct.nama}
                  </li>
                </>
              ) : (
                ""
              )}
            </ol>
          </nav>
          {/* Detail */}

          <div className="row mt-5 align-items-ceter">
            <div className="col-lg-6 col-12 text-center">
              <FsLightbox toggler={toggler} sources={[image]} type="image" />
              {isDesktopScreen && (
                <div id="img-magnifer">
                  {pageLoad ? (
                    <Skeleton width={500} height={310}></Skeleton>
                  ) : (
                    <figure
                      class="zoom"
                      onMouseMove={(e) => zoom(e)}
                      style={{
                        backgroundImage: `url(${image})`,
                      }}>
                      <img src={image} alt={image} />
                    </figure>
                  )}
                </div>
              )}

              {isPhoneScreen && (
                <>
                  {pageLoad ? (
                    <Skeleton width={500} height={310}></Skeleton>
                  ) : (
                    <img
                      src={image}
                      alt={image}
                      className="main-image-product"
                      onClick={() => setToggler(!toggler)}></img>
                  )}
                </>
              )}

              <div className="list-detail-img mt-4">
                {pageLoad ? (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable
                    infinite
                    focusOnSelect={false}>
                    {["", "", "", ""].map((e, i) => (
                      <div className="detail-img">
                        <Skeleton
                          width={90}
                          height={80}
                          borderRadius={2}></Skeleton>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable
                    infinite
                    focusOnSelect={false}>
                    {imageList.map((value, index) => (
                      <div className="detail-img">
                        <img
                          src={imageUrl.product(value.nama_file)}
                          alt={imageUrl.product(value.nama_file)}
                          onClick={(e) => getImage(value.index)}></img>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="wrapper-content-product">
                <div className="name">
                  {pageLoad ? (
                    <Skeleton width={"80%"} height={40}></Skeleton>
                  ) : (
                    detailProduct.nama
                  )}
                </div>
                <div className="d-flex align-items-ceter flex-wrap">
                  <div className="number">
                    {pageLoad ? (
                      <Skeleton width={120} height={20}></Skeleton>
                    ) : (
                      detailProduct.sku
                    )}
                  </div>
                  <div className="line">|</div>
                  <div className="list-star d-flex align-items-ceter">
                    {pageLoad ? (
                      <Skeleton width={50} height={20}></Skeleton>
                    ) : (
                      <>
                        <img src={star} alt={star} className="me-1"></img>
                        <div className="text">{detailProduct.rating}</div>
                      </>
                    )}
                  </div>
                  <div className="line">|</div>
                  <div className="text reviews">
                    {pageLoad ? (
                      <Skeleton width={80} height={20}></Skeleton>
                    ) : (
                      `${detailProduct.total_review} Reviews`
                    )}
                  </div>
                  <br></br>
                  {!pageLoad ? (
                    <>
                      <div className="line">|</div>
                      <div className="d-flex align-items-center share">
                        <div className="text me-2">Share</div>
                        <div className="sosmed">
                          <ul>
                            <li>
                              <Link
                                target="_blank"
                                to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}>
                                <img
                                  src={facebook}
                                  alt={facebook}
                                  className="me-2"></img>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to={`https://twitter.com/intent/tweet?text=${window.location.href}`}>
                                <img
                                  src={twitter}
                                  alt={twitter}
                                  className="me-2"></img>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to={`https://wa.me/?text=${window.location.href}`}>
                                <img src={wa} alt={wa} className="me-2"></img>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to={`https://t.me/share/url?url=${window.location.href}`}>
                                <img
                                  src={telegram}
                                  alt={telegram}
                                  className="me-2"></img>
                              </Link>
                            </li>
                            {/* <li>
                          <img
                            src={instagram}
                            alt={instagram}
                            className="me-2"></img>
                        </li> */}
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <hr></hr>
                <div className="mt-3 product-detail">
                  <div className="d-flex item-detail">
                    <div className="text">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : (
                        "Harga"
                      )}
                    </div>
                    <div className="value">
                      <div className="">
                        {!pageLoad &&
                          detailProduct.harga_promo &&
                          detailProduct.harga_promo != "0" ? (
                          <>
                            <div className="d-flex align-items-center">
                              <div className="discount">
                                Disc. {checkSatuan(detailProduct.satuan_promo)}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="d-flex align-items-center mt-2">
                          {!pageLoad &&
                            detailProduct.harga_promo &&
                            detailProduct.harga_promo != "0" ? (
                            <div className="original-price">
                              {pageLoad ? (
                                <Skeleton width={80} height={20}></Skeleton>
                              ) : (
                                `Rp ${formatMoney(detailProduct.harga)}`
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={`discount-price ${detailProduct.harga_promo ? "ms-2" : "black"
                              }`}>
                            {pageLoad ? (
                              <Skeleton width={130} height={20}></Skeleton>
                            ) : (
                              `Rp ${formatMoney(
                                detailProduct.harga_promo ? detailProduct.harga_promo : detailProduct.harga
                              )}`
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!pageLoad && varianList.length > 0 ? (
                    <div className="d-flex item-detail">
                      <div className="text">Varian</div>
                      <div className="value" style={{ minWidth: "100px" }}>
                        <Select
                          value={varianSelected}
                          options={varianList}
                          placeholder="Choose Varian"
                          styles={colourStyles}
                          onChange={(val) => setVarianSelected(val)}></Select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex item-detail">
                    <div className="text">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : (
                        "Warranty"
                      )}
                    </div>
                    <div className="value">
                      {pageLoad ? (
                        <Skeleton width={130} height={20}></Skeleton>
                      ) : (
                        detailProduct.warranty
                      )}
                    </div>
                  </div>
                  <div className="d-flex item-detail">
                    <div className="text">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : (
                        "Stock"
                      )}
                    </div>
                    <div className="value text-capitalize">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : detailProduct.status == 'in stock' ? 'In Stock' : <>
                        Pre Order {detailProduct.day_preorder > 0 ? `(${detailProduct.day_preorder} Days)` : ''}
                      </>}
                    </div>
                  </div>

                  <div className="d-flex item-detail align-items-start">
                    <div className="text">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : (
                        "Features"
                      )}
                    </div>
                    <div className="value">
                      {pageLoad ? (
                        <Skeleton width={80} height={20}></Skeleton>
                      ) : (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: detailProduct.fitur,
                            }}></div>
                          {/* <ul className="list-features">
                          <li>High resolution LCD dan EVF</li>
                          <li>Multi-Interface (MI) Shoe</li>
                          <li>Wide choice of connections</li>
                          <li>Dual media slots for recording flexibility</li>
                          <li>Built-in ND Filter</li>
                        </ul> */}
                        </>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex item-detail justify-content-between">
                    {/* <div className="text">Quantity</div>
                    <div
                      className="value d-flex me-4"
                      style={{ width: "100px" }}>
                      <button type="button" className="btn-quantity">
                        <svg
                          width="12"
                          height="4"
                          viewBox="0 0 12 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.33301 2H10.6663"
                            stroke="#25282B"
                            stroke-width="2.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <input
                        type="text"
                        readOnly
                        value="1"
                        className="total-quantity"></input>
                      <button type="button" className="btn-quantity">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 1.33333V10.6667"
                            stroke="#25282B"
                            stroke-width="2.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.33301 6H10.6663"
                            stroke="#25282B"
                            stroke-width="2.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div> */}
                    {!pageLoad ? (
                      <>
                        {detailProduct.link_blibli ? (
                          <div className="wrapper-btn">
                            <Link
                              target="_blank"
                              to={checkUrl(detailProduct.link_blibli)}
                              className="btn-blue d-flex justify-content-center align-items-center">
                              <img
                                src={blibli}
                                alt={blibli}
                                className="me-2"></img>
                              Buy at Blibli
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                        {detailProduct.link_shopee ? (
                          <div className="wrapper-btn">
                            <Link
                              target="_blank"
                              to={checkUrl(detailProduct.link_shopee)}
                              className="btn-orange d-flex justify-content-center align-items-center">
                              <img
                                src={shopee}
                                alt={shopee}
                                className="me-2"></img>
                              Buy at Shopee
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                        {detailProduct.link_tokped ? (
                          <div className="wrapper-btn">
                            <Link
                              target="_blank"
                              to={checkUrl(detailProduct.link_tokped)}
                              className="btn-green d-flex justify-content-center align-items-center">
                              <img
                                src={tokped}
                                alt={tokped}
                                className="me-2"></img>
                              Buy at Tokopedia
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {isDesktopScreen && (
                <Tabs
                  defaultActiveKey="Description"
                  id="uncontrolled-tab-example"
                  className="mb-3">
                  <Tab eventKey="Description" title="Description">
                    <div className="pt-3 mt-3">
                      {pageLoad ? (
                        ["", "", ""].map((value, index) => (
                          <div className="mt-3">
                            <Skeleton
                              width={"100%"}
                              height={"10"}
                              count={5}></Skeleton>
                            <Skeleton width={"40%"} height={"10"}></Skeleton>
                          </div>
                        ))
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detailProduct.deskripsi,
                          }}></div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="Specification" title="Specification">
                    <div className="pt-3 mt-3">
                      {pageLoad ? (
                        ["", "", ""].map((value, index) => (
                          <div className="mt-3">
                            <Skeleton
                              width={"100%"}
                              height={"10"}
                              count={5}></Skeleton>
                            <Skeleton width={"40%"} height={"10"}></Skeleton>
                          </div>
                        ))
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detailProduct.spesifikasi,
                          }}></div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="Reviews" title="Reviews">
                    <div className="d-flex mt-3 pt-3 flex-wrap align-items-center">
                      <div className=" wrapper-rating">
                        <div
                          className={`d-flex ${pageLoad ? "justify-content-center" : ""
                            }`}>
                          {!pageLoad ? (
                            <img src={star} alt={star} className="star"></img>
                          ) : (
                            ""
                          )}
                          <div className="rating ">
                            {pageLoad ? (
                              <Skeleton width={80} height={40}></Skeleton>
                            ) : (
                              detailProduct.rating
                            )}
                          </div>
                        </div>
                        <div className="total-reviews text-center">
                          {pageLoad ? (
                            <Skeleton width={130} height={20}></Skeleton>
                          ) : (
                            `${detailProduct.total_review} reviews`
                          )}
                        </div>
                      </div>
                      <div className="wrapper-review-bar">
                        {pageLoad ? (
                          ["", "", "", "", ""].map((e, i) => (
                            <div className="d-flex align-items-center review-bar">
                              <Skeleton width={20} height={20}></Skeleton>
                              <div className="ms-2">
                                <Skeleton width={150} height={20}></Skeleton>
                              </div>
                            </div>
                          ))
                        ) : (
                          <>
                            {dataReview.rating.map((value, index) => (
                              <div
                                className="d-flex align-items-center review-bar"
                                key={`review-number${index}`}>
                                <img
                                  src={star}
                                  alt={star}
                                  className="star"></img>
                                <div className="total-bar font-500">
                                  {5 - index}
                                </div>
                                <div className="wrapper-bar">
                                  <div
                                    className="bar"
                                    style={{
                                      width: `${value
                                        ? (value /
                                          detailProduct.total_review) *
                                        100
                                        : 0
                                        }%`,
                                    }}></div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="list-reviewer">
                      {pageLoad ? (
                        ["", ""].map((e, i) => (
                          <div
                            className="item-reviewer"
                            key={`skeleton-review1-${i}`}>
                            <div className="d-flex align-items-center mb-3">
                              <div>
                                <div className="name">
                                  <Skeleton width={150} height={20}></Skeleton>
                                </div>
                                <div className="d-flex">
                                  <Skeleton width={250} height={20}></Skeleton>
                                  {/* <div className="date">2 Juli 2023</div> */}
                                </div>
                              </div>
                            </div>
                            <div className="desc">
                              <div className="mt-3">
                                <Skeleton
                                  width={"100%"}
                                  height={"10"}
                                  count={2}></Skeleton>
                                <Skeleton
                                  width={"40%"}
                                  height={"10"}></Skeleton>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          {dataReview.list.map((value, index) => (
                            <div
                              className="item-reviewer"
                              key={`review-item${index}`}>
                              <div className="d-flex align-items-center mb-3">
                                {/* <div className="">
                                  <img
                                    src={review}
                                    alt={review}
                                    className="img-reviewer"></img>
                                </div> */}
                                <div>
                                  <div className="name">{value.nama}</div>
                                  <div className="d-flex">
                                    {generateStarReview(
                                      parseFloat(value.nilai)
                                    )}
                                    <div className="date">
                                      {formatDate(value.waktu)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="desc">{value.review}</div>
                            </div>
                          ))}
                        </>
                      )}
                      {!pageLoad && dataReview.all.length == 0 ? (
                        <div className="text-center my-4">
                          <img
                            src={emptyReview}
                            height={100}
                            alt="empty-review"></img>
                          <div
                            style={{ color: "#9A9A9A", fontSize: "1rem" }}
                            className="mt-4">
                            No review yet
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex justify-content-center mt-3">
                        {pagingReview.page > 1 ? (
                          <>
                            <button
                              className="btn me-3"
                              style={{
                                padding: "8px 16px",
                                border: "1px solid #979797",
                              }}
                              onClick={() =>
                                setPagingReview({
                                  ...pagingReview,
                                  page: pagingReview.page - 1,
                                })
                              }>
                              Prev
                            </button>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {dataReview.all.length > 0 &&
                          pagingReview.page != pagingReview.totalPage ? (
                          <>
                            <button
                              className="btn"
                              style={{
                                padding: "8px 16px",
                                border: "1px solid #979797",
                              }}
                              onClick={() =>
                                setPagingReview({
                                  ...pagingReview,
                                  page: pagingReview.page + 1,
                                })
                              }>
                              Next
                            </button>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              )}
              {isPhoneScreen ? (
                <>
                  {pageLoad ? (
                    <Skeleton height={60} count={3} borderRadius={4}></Skeleton>
                  ) : (
                    <Accordion allowZeroExpanded>
                      <AccordionItem id="description">
                        <AccordionItemHeading onClick={(e) => {
                          if (accordionOpen == "description") {
                            setAccordionOpen('');
                          } else {
                            // scrool to ref
                            setAccordionOpen("description");
                          }
                        }}>
                          <AccordionItemButton>Description</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {pageLoad ? (
                            ["", "", ""].map((value, index) => (
                              <div className="mt-3">
                                <Skeleton
                                  width={"100%"}
                                  height={"10"}
                                  count={5}></Skeleton>
                                <Skeleton
                                  width={"40%"}
                                  height={"10"}></Skeleton>
                              </div>
                            ))
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: detailProduct.deskripsi,
                              }}></div>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem id="spesifikasi">
                        <AccordionItemHeading onClick={(e) => {
                          if (accordionOpen == "spesifikasi") {
                            setAccordionOpen('');
                          } else {
                            // scrool to ref
                            setAccordionOpen("spesifikasi");
                          }
                        }}>
                          <AccordionItemButton>
                            Spesification
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {pageLoad ? (
                            ["", "", ""].map((value, index) => (
                              <div className="mt-3">
                                <Skeleton
                                  width={"100%"}
                                  height={"10"}
                                  count={5}></Skeleton>
                                <Skeleton
                                  width={"40%"}
                                  height={"10"}></Skeleton>
                              </div>
                            ))
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: detailProduct.spesifikasi,
                              }}></div>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem id="review">
                        <AccordionItemHeading onClick={(e) => {
                          if (accordionOpen == "review") {
                            setAccordionOpen('');
                          } else {
                            // scrool to ref
                            setAccordionOpen("review");
                          }
                        }}>
                          <AccordionItemButton>Reviews</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="d-flex mt-3 pt-3 flex-wrap justify-content-center">
                            <div className="wrapper-rating text-center">
                              <div className="rating">
                                {detailProduct.rating}
                              </div>
                              <div className="d-flex">
                                {generateStarReview(
                                  parseFloat(detailProduct.rating)
                                )}
                              </div>
                              <div className="total-reviews">
                                {detailProduct.total_review} reviews
                              </div>
                            </div>
                            <div className="wrapper-review-bar ms-0">
                              {dataReview.rating.map((value, index) => (
                                <div
                                  className="d-flex align-items-center review-bar"
                                  key={`review-number${index}`}>
                                  <img
                                    src={star}
                                    alt={star}
                                    className="star"></img>
                                  <div className="total-bar font-500">
                                    {5 - index}
                                  </div>
                                  <div className="wrapper-bar">
                                    <div
                                      className="bar"
                                      style={{
                                        width: `${value
                                          ? (value /
                                            detailProduct.total_review) *
                                          100
                                          : 0
                                          }%`,
                                      }}></div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="list-reviewer">
                            {dataReview.list.map((value, index) => (
                              <div
                                className="item-reviewer"
                                key={`review-item${index}`}>
                                <div className="d-flex align-items-center mb-3">
                                  <div>
                                    <div className="name">{value.nama}</div>
                                    <div className="d-flex">
                                      {generateStarReview(
                                        parseFloat(value.nilai)
                                      )}
                                      <div className="date">
                                        {formatDate(value.waktu)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="desc">{value.review}</div>
                              </div>
                            ))}
                          </div>

                          {!pageLoad && dataReview.all.length == 0 ? (
                            <div className="text-center my-4">
                              <img
                                src={emptyReview}
                                height={100}
                                alt="empty-review"></img>
                              <div
                                style={{ color: "#9A9A9A", fontSize: "1rem" }}
                                className="mt-4">
                                No review yet
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="d-flex justify-content-center mt-3">
                            {pagingReview.page > 1 ? (
                              <>
                                <button
                                  className="btn me-3"
                                  style={{
                                    padding: "8px 16px",
                                    border: "1px solid #979797",
                                  }}
                                  onClick={() =>
                                    setPagingReview({
                                      ...pagingReview,
                                      page: pagingReview.page - 1,
                                    })
                                  }>
                                  Prev
                                </button>{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {dataReview.all.length > 0 &&
                              pagingReview.page != pagingReview.totalPage ? (
                              <>
                                <button
                                  className="btn"
                                  style={{
                                    padding: "8px 16px",
                                    border: "1px solid #979797",
                                  }}
                                  onClick={() =>
                                    setPagingReview({
                                      ...pagingReview,
                                      page: pagingReview.page + 1,
                                    })
                                  }>
                                  Next
                                </button>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* End detail */}

          {/* Related Products */}
          {!pageLoad && otherProduct.length > 0 ? (
            <>
              <div className="title-section text-center mt-5">
                Related Products and Accessories
              </div>
            </>
          ) : (
            ""
          )}
          <div className="list-item  mt-5">
            {pageLoad ? (
              <>
                <Carousel
                  responsive={responsiveRealated}
                  showDots={false}
                  arrows={false}
                  draggable={false}
                  infinite={false}
                  focusOnSelect={false}>
                  {["", "", "", "", ""].map((e, i) => (
                    <SekeletonCardProduct
                      key={`best-offers${i}`}></SekeletonCardProduct>
                  ))}
                </Carousel>
              </>
            ) : (
              <>
                {otherProduct.length > 0 ? (
                  <Carousel
                    responsive={responsiveRealated}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {otherProduct.map((value, index) => (
                      <CardProduct
                        key={`other${index}`}
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}
                        onClick={(e) => getProduct(value.url)}></CardProduct>
                    ))}
                  </Carousel>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {/* End Related Product */}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
