import React, { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes, json, useLocation } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

// import "../src/assets/scss/modal/modal.css";

// import { Nav } from "./parts/Nav";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollTop from "./helpers/ScrollTop";
import { Home } from "./pages/Home";
import { ListProduct } from "./pages/ListProduct";
import { DetailProduct } from "./pages/DetailProduct";
import { DetailBlog } from "./pages/DetailBlog";
import { ListBlog } from "./pages/ListBlog";
import { Cart } from "./pages/Cart";
import { Checkout } from "./pages/Checkout";
import { Payment } from "./pages/Payment";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { SignUp } from "./pages/SignUp";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import { Terms } from "./pages/Terms";
import { Warranty } from "./pages/Warranty";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { MyAccount } from "./pages/Account/MyAccount";
import { Password } from "./pages/Account/Password";
import { Transaction } from "./pages/Account/Transaction/Transaction";
import { DetailTransaction } from "./pages/Account/Transaction/DetailTransaction";
import { Address } from "./pages/Account/Address";
import "bootstrap/dist/css/bootstrap.min.css";
import { get_BrandList, get_CategoryList, get_MetaData } from "./actions";
import MetaTags from "./helpers/MetaTags";
import { OurPromo } from "./pages/OurPromo";
import Analytics from "./helpers/Analytics";
import ReactGA from 'react-ga4';
import { getAnalyticsId } from "./helpers";
export default function App() {
  ReactGA.initialize(getAnalyticsId());

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  });
  useEffect(() => {
    get_CategoryList({}, (res) => {
      localStorage.setItem('_ctg', JSON.stringify(res.list));
    });

    get_BrandList({}, (res) => {
      localStorage.setItem('_brd', JSON.stringify(res.list));
    });

    get_MetaData((res) => {
      setMetaData(res.default);
      localStorage.setItem('_mta', JSON.stringify(res.default));
    });

  }, [])

  return (
    <Router>
      <MetaTags title={'drmo store'} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <ScrollTop></ScrollTop>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/product" element={<ListProduct />}></Route>
        <Route path="/best-seller" element={<ListProduct />}></Route>
        <Route path="/product/:url" element={<DetailProduct />}></Route>
        <Route path="/blog" element={<ListBlog />}></Route>
        <Route path="/blog/:url" element={<DetailBlog />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-conditions" element={<Terms />}></Route>
        <Route path="/warranty" element={<Warranty />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/our-promo" element={<OurPromo />}></Route>

        <Route path="*" element={<Navigate to="/" replace />} />
        {/* <Route path="/cart" element={<Cart />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route path="/payment" element={<Payment />}></Route>
        <Route path="/my-account" element={<MyAccount />}></Route>
        <Route path="/password" element={<Password />}></Route>
        <Route path="/transaction" element={<Transaction />}></Route>
        <Route path="/transaction/detail" element={<DetailTransaction />}></Route>
        <Route path="/address" element={<Address />}></Route> */}
      </Routes>
    </Router>
  );
}
