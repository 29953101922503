import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imgProduct2 from "../../assets/img/img-product-2.png";
import star from "../../assets/img/icon/star.svg";
import starOutline from "../../assets/img/icon/star-outline.svg";
import iconCheckout from "../../assets/img/icon/icon-checkout.svg";
import CardProduct from "../../components/element/CardProduct/CardProduct";

import "../../assets/scss/home/home.css";
import { Link } from "react-router-dom";
import SekeletonCardProduct from "../element/CardProduct/CardProductSekeleton";
import { imageUrl } from "../../actions/image";

export function BestDeal({ list, loading }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };
  return (loading || (!loading && list.length > 0)) &&  (
    <>
      <div className="container">
        <div className="" id="best-deal">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title-section">Our Promo</div>
            {!loading && (<Link to="/our-promo" className="see-all">See All</Link>)}
          </div>

          <div className="list-item  mt-4">
            {loading && (
              <Carousel
                responsive={responsive}
                showDots={false}
                arrows={false}
                draggable
                infinite
                focusOnSelect={false}>
                {['', '', '', '', ''].map((e, i) => (
                  <SekeletonCardProduct key={`best-deal${i}`}></SekeletonCardProduct>
                ))}
              </Carousel>
            )}
            {!loading && (<Carousel
              responsive={responsive}
              showDots={false}
              arrows={true}
              draggable={false}
              infinite={true}
              focusOnSelect={false}>
              {list.map((value, index) => (
                <CardProduct
                  key={`bestdeal${index}`}
                  discount={value.satuan_promo}
                  category={value.category}
                  name={value.nama}
                  image={imageUrl.product(value.gambar)}
                  url={value.url}
                  rating={value.rating}
                  review={value.total_review}
                  originalPrice={value.harga}
                  discountPrice={value.harga_promo}></CardProduct>
              ))}

            </Carousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
