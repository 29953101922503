
import star from "../../../assets/img/icon/star.svg";
import starOutline from "../../../assets/img/icon/star-outline.svg";
import iconCheckout from "../../../assets/img/icon/icon-checkout.svg";
import Skeleton from "react-loading-skeleton";

const SekeletonCardProduct = (props) => {
    return (
        <div className="item" style={props.style}>
        <div className="text-center">
          <Skeleton width={150} height={150}></Skeleton>
        </div>
        <div className="text-product">
          <div className="category"><Skeleton width={90}></Skeleton></div>
          <div className="name"><Skeleton width={110}></Skeleton></div>
          <div className="d-flex align-items-center mt-2">
            <Skeleton width={90}></Skeleton>
            {/* <img src={star} alt={star} className="star"></img> */}
            {/* <div className="" style={{fontSize:"14px", color: "#9a9a9a"}}>4.8</div> */}
            <div className="line">|</div>
            <Skeleton width={90}></Skeleton>
            {/* <div className="total-review">{review} Reviews</div> */}
          </div>
          <div className="d-flex price align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap">
              <div className="discount-price"><Skeleton width={110}></Skeleton></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default SekeletonCardProduct;
  