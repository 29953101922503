import axios from "axios"
import Cookies from "universal-cookie"
import moment from 'moment'
import 'moment/locale/id'
import CryptoJS from "crypto-js";

const cksClient = () => {
    var cookies = new Cookies()

    return cookies
}

const apiClient = (withBearer = true) => {
    var _url = getApiEndpoint();
    const _tkn = cksClient().get('_authToken')
    const options = {
        baseURL: _url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'tkid': '4e7f9189-c7d6-4e5d-ae0d-8e7d7a93d88c',
        },
        timeout: 600000,
    }

    if (withBearer) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${_tkn}`
        }
    }

    const client = axios.create(options)
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError)
        }
    )

    client.interceptors.response.use(
        response => Promise.resolve(response),
        error => {
            if (error.response) {
                const { status } = error.response
                if (status === 403) {
                    console.log('Global api check - Forbidden Access')
                }
            }

            return Promise.reject(error)
        }
    )

    return client
}

const formatMoney = (a) => {
    if (!a) {
        return '0';
    }

    return a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const formatDate = (d, f = 'DD MMMM YYYY') => {
    if (!d) {
        return ''
    }
    var date = moment(d).utcOffset('+07:00');

    return date.format(f);
}

const removeHtmlTags = (inputString) => {
    return inputString.replace(/<[^>]*>/g, '');
}

const convertIsi = (isi) => {
    const imgRegex = /<img\b[^>]*>/gi;
    const sanitizedContent = isi.replace(imgRegex, '');
    return removeHtmlTags(sanitizedContent);
}

const cryptoJs = (type = 'encrypt', text = '') => {
    var secretKey = 'c898cf7378654303b883d397707f2d84';
    if (type == 'encrypt') {
        return CryptoJS.AES.encrypt(text, secretKey).toString();
    } else if (type == 'decrypt') {
        return CryptoJS.AES.decrypt(text, secretKey).toString(CryptoJS.enc.Utf8);
    }
}

const getApiEndpoint = () => {
    const domain = window.location.hostname;
    
    if (domain.indexOf('drmostore.com') >= 0) {
        return 'https://api.drmostore.com/api/drmo';
    } else if (domain.indexOf('drmo.store') >= 0) {
        return 'https://api.drmo.store/api/drmo';
    } else {
        // Default endpoint if domain doesn't match any condition
        return process.env.REACT_APP_API_URL;
    }
}

const getAssetEndpoint = () => {
    const domain = window.location.hostname;
    
    if (domain.indexOf('drmostore.com') >= 0) {
        return 'https://asset.drmostore.com';
    } else if (domain.indexOf('drmo.store') >= 0) {
        return 'https://asset.drmo.store';
    } else {
        // Default endpoint if domain doesn't match any condition
        return process.env.REACT_APP_DATA_URL;
    }
}

const getAnalyticsId = () => {
    const domain = window.location.hostname;
    
    if (domain.indexOf('drmostore.com') >= 0) {
        return 'G-EVSENK0J4E';
    } else if (domain.indexOf('drmo.store') >= 0) {
        return 'G-FZE3NLSK0V';
    } else {
        // Default endpoint if domain doesn't match any condition
        return "G-FZE3NLSK0V";
    }
}

export {
    apiClient, cksClient, formatMoney,
    formatDate, convertIsi,cryptoJs,
    getAssetEndpoint,
    getAnalyticsId
}