import Skeleton from "react-loading-skeleton";

const SkeletonCardBlog = (props) => {
    return (
      <div className="item-blog">
        <Skeleton width={'100%'} height={252}></Skeleton>
        <div className="text-product">
          <div className="category"><Skeleton width={80} height={30} borderRadius={2}></Skeleton></div>
          <div className="name"><Skeleton width={'80%'} height={30} borderRadius={2}></Skeleton></div>
          <div className="desc"><Skeleton width={'100%'} height={10} borderRadius={2} count={2}></Skeleton></div>
          <div className="date"><Skeleton width={60} height={10} borderRadius={2}></Skeleton></div>
        </div>
      </div>
    );
  };
  
  export default SkeletonCardBlog;
  