import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/login/login.css";
import banner from "../assets/img/img-banner.png";
import LogoBrand from "../assets/img/logo-hitam.png";
import eyeOn from "../assets/img/icon/eye-on.svg";
import eyeOff from "../assets/img/icon/eye-off.svg";

export function SignUp() {
  const [passwordShown, setPasswordShown] = useState({
    baru: "password",
    repeat: "password",
  });
  return (
    <>
      <section className="login">
        <div className="row ">
          <div className="col-md-6 d-table col-content">
            <div className=" d-table-cell align-middle content">
              <div className="card">
                <div className="logo-brand">
                  <img src={LogoBrand} alt={LogoBrand} className=""></img>
                </div>
                <div className="title-form">
                  <div className="welcome-text">Sign Up</div>
                  <div className="desc-text">
                    Enter your email and password to access your account.
                  </div>
                </div>
                <form
                  className="needs-validation mt-4"
                  method="POST"
                  id="LoginForm">
                  <div className="form-group mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter name"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Enter phone number"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Password</label>
                    <div className="password-area position-relative">
                      <input
                        type={
                          passwordShown.baru === "text" ? "text" : "password"
                        }
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        required
                      />
                       <img
                          src={passwordShown.baru === "text" ? eyeOn : eyeOff}
                          alt={passwordShown.baru === "text" ? eyeOn : eyeOff}
                          onClick={(e) => {
                            var prev = passwordShown.repeat;
                            setPasswordShown({
                              ...passwordShown,
                              baru: prev === "text" ? "password" : "text",
                            });
                          }}
                          className="icon"></img>
                    </div>
                    <div className="form-error" id="error_password"></div>
                  </div>
                  <div className="form-group">
                    <label>Re-Password</label>
                    <div className="password-area position-relative">
                      <input
                        type={
                          passwordShown.repeat === "text" ? "text" : "password"
                        }
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter re-password"
                        required
                      />
                       <img
                          src={passwordShown.repeat === "text" ? eyeOn : eyeOff}
                          alt={passwordShown.repeat === "text" ? eyeOn : eyeOff}
                          onClick={(e) => {
                            var prev = passwordShown.repeat;
                            setPasswordShown({
                              ...passwordShown,
                              repeat: prev === "text" ? "password" : "text",
                            });
                          }}
                          className="icon"></img>
                    </div>
                    <div className="form-error" id="error_password"></div>
                  </div>
                  <button
                    className="btn-black mt-4 w-100"
                    id="submitForm"
                    type="submit">
                    <span className="btn-login">Signup</span>
                  </button>
                  <div className="mt-3">
                    Do you have an account?{" "}
                    <Link to="/login">
                      <span
                        style={{
                          fontWeight: "700",
                          textDecoration: "underline",
                        }}>
                        Log In
                      </span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-table col-banner">
            <div className=" banner d-table-cell align-middle">
              <div className="text-banner">
                <div className="title">
                  Unlock Your Access to Cutting-Edge <br></br> Multimedia
                  Equipment at Drmo Store.
                </div>
                <div className="sub-title">Log in to Explore Excellence!</div>
              </div>
              <img src={banner} alt={banner} className="img-banner"></img>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
