import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import { get_AboutUs } from "../actions";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../actions/image";
import MetaTags from "../helpers/MetaTags";
import { useMediaQuery } from "react-responsive";
import { NavAbouts } from "../components/element/NavAboutus";
import Analytics from "../helpers/Analytics";
export function AboutUs() {
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [pageLoad, setPageLoad] = useState(true);
  const [contentAboutUs, setAboutUs] = useState({
    title: "",
    content: "",
    gambar: "",
  });
  const [contentOurStory, setOurStory] = useState({
    title: "",
    content: "",
    gambar: "",
  });

  const [contentOurMission, setOurMission] = useState({
    title: "",
    content: "",
    gambar: "",
  });

  const [metaData, setMetaData] = useState({
    website_name: "",
    website_desc: "",
    website_keyword: "",
    address: "",
    phone: "",
    phone_number: "",
    email: "",
    coordinate: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    phone_wa: "",
  });

  const [brand, setbrand] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: true,
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  const responsiveNav = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: true,
      breakpoint: { max: 767, min: 0 },
      items: 3,
    },
  };

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem("_mta");
      if (meta) {
        setMetaData({ ...metaData, ...JSON.parse(meta) });
        clearInterval(intervalCheckMeta);
      }
    }, 20);

    setPageLoad(true);
    get_AboutUs((res) => {
      const { brand, aboutUs, ourStory, ourMission } = res;
      setbrand(brand);
      setAboutUs({ ...contentAboutUs, ...aboutUs });
      setOurStory({ ...contentOurStory, ...ourStory });
      setOurMission({ ...contentOurMission, ...ourMission });
      setPageLoad(false);
    });
  }, []);
  return (
    <>
      <MetaTags
        title={"About Us - drmo store"}
        url={window.location.href}
        description={metaData.website_desc}
        keyword={metaData.website_keyword}
        site_nime={metaData.website_name}
        image={imageUrl.blog(contentAboutUs.gambar)}></MetaTags>
      <Analytics title={ 'About Us - drmo store'}></Analytics>
      <Nav active="home"></Nav>

      <div className="container">
        <div className="section section-information" id="">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>

          <div className="row mt-5">
            <NavAbouts></NavAbouts>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12 col-lg-7">
                  {pageLoad ? (
                    <>
                      <Skeleton width={"80%"} height={40}></Skeleton>
                      {["", ""].map((value, index) => (
                        <div className="mt-3">
                          <Skeleton
                            width={"100%"}
                            height={"10"}
                            count={4}></Skeleton>
                          <Skeleton width={"40%"} height={"10"}></Skeleton>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="title-section">About Our Company</div>
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: contentAboutUs.content,
                        }}></div>
                    </>
                  )}
                </div>
                <div className="col-12 col-lg-5 ">
                  {pageLoad ? (
                    <Skeleton width={"100%"} height={"300px"}></Skeleton>
                  ) : (
                    <img
                      src={imageUrl.blog(contentAboutUs.gambar)}
                      alt={imageUrl.blog(contentAboutUs.gambar)}
                      className="w-100 img-about"></img>
                  )}
                </div>
              </div>
              <div className="text-center" style={{ marginTop: "80px" }}>
                <div className="title-section">
                  {pageLoad ? (
                    <Skeleton height={40} width={200}></Skeleton>
                  ) : (
                    "Our Trusted Brand"
                  )}
                </div>
              </div>
              {pageLoad ? (
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={false}
                  autoPlay={false}
                  infinite
                  draggable>
                  {["", "", "", "", ""].map((e, i) => (
                    <div className="item-brand me-2" key={`brand-aboutus${i}`}>
                      <Skeleton width={"100%"} height={80}></Skeleton>
                    </div>
                  ))}
                </Carousel>
              ) : (
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={false}
                  autoPlay={true}
                  autoPlaySpeed={2000}
                  infinite
                  draggable>
                  {brand.map((e, i) => (
                    <div className="item-brand" key={`brand-aboutus${i}`}>
                      <img
                        src={imageUrl.product_brand(e.gambar)}
                        alt={imageUrl.product_brand(e.gambar)}
                        className="img-brand"></img>
                    </div>
                  ))}
                </Carousel>
              )}
              <div className="row" style={{ marginTop: "80px" }}>
                <div className="col-12 col-lg-7">
                  {pageLoad ? (
                    <>
                      <Skeleton width={"80%"} height={40}></Skeleton>
                      {["", ""].map((value, index) => (
                        <div className="mt-3">
                          <Skeleton
                            width={"100%"}
                            height={"10"}
                            count={4}></Skeleton>
                          <Skeleton width={"40%"} height={"10"}></Skeleton>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="sub-title">Our Story</div>
                      <div className="title-section">
                        {contentOurStory.title}
                      </div>
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: contentOurStory.content,
                        }}></div>
                    </>
                  )}
                </div>
                <div className="col-lg-5 col-12">
                  {pageLoad ? (
                    <Skeleton width={"100%"} height={"300px"}></Skeleton>
                  ) : (
                    <img
                      src={imageUrl.blog(contentOurStory.gambar)}
                      alt={imageUrl.blog(contentOurStory.gambar)}
                      className="w-100 img-about"></img>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: "80px" }}>
                <div className="col-12 col-lg-5 mb-4 order-2 order-lg-1">
                  {pageLoad ? (
                    <Skeleton width={"100%"} height={"300px"}></Skeleton>
                  ) : (
                    <img
                      src={imageUrl.blog(contentOurMission.gambar)}
                      alt={imageUrl.blog(contentOurMission.gambar)}
                      className="w-100 img-about"></img>
                  )}
                </div>
                <div className="col-12 col-lg-7 mb-4 order-1 order-lg-2">
                  {pageLoad ? (
                    <>
                      <Skeleton width={"80%"} height={40}></Skeleton>
                      {["", ""].map((value, index) => (
                        <div className="mt-3">
                          <Skeleton
                            width={"100%"}
                            height={"10"}
                            count={4}></Skeleton>
                          <Skeleton width={"40%"} height={"10"}></Skeleton>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="sub-title">Our Mission</div>
                      <div className="title-section">
                        {contentOurMission.title}
                      </div>
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: contentOurMission.content,
                        }}></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
