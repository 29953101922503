import React, { useEffect, useState } from "react";
import { Link, useFetcher, useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/list_product/list_product.css";
import imgProduct2 from "../assets/img/img-product-2.png";
import imgPromo from "../assets/img/img-promo.png";
import CardProduct from "../components/element/CardProduct/CardProduct";
import SekeletonCardProduct from "../components/element/CardProduct/CardProductSekeleton";
import { get_ProductList } from "../actions";
import { imageUrl } from "../actions/image";
import productEmpty from "../assets/img/empty-product.svg";
import MetaTags from "../helpers/MetaTags";
import { get_PromoList } from "../actions";
import { cryptoJs } from "../helpers";
import Skeleton from "react-loading-skeleton";
import Analytics from "../helpers/Analytics";

export function OurPromo() {
  const location = useLocation();
  const [modalBackdrop, setBackdrop] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [onPageLoad, setPageLoad] = useState(true);
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [ourPromo, setOurPromo] = useState([]);
  const [metaData, setMetaData] = useState({
    website_name: "",
    website_desc: "",
    website_keyword: "",
    address: "",
    phone: "",
    phone_number: "",
    email: "",
    coordinate: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    phone_wa: "",
  });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };
  const getOurPromo = () => {
    setPageLoad(true);
    get_PromoList({}, (res) => {
      const { promo } = res;
      var promoFix = promo.map((item) => {
        var hash = encodeURIComponent(cryptoJs('encrypt', JSON.stringify({
          id: item.id,
          nama: item.nama
        })));

        return { ...item, hash: hash }
      })
      setPageLoad(false);
      setOurPromo(promoFix)
    });
  };

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    getOurPromo();
  }, []);

  return (
    <>
      <MetaTags
        title={"Our Promo - drmo store"}
        url={window.location.href}
        description={metaData.website_desc}
        keyword={metaData.website_keyword}
        site_nime={metaData.website_name}></MetaTags>
        <Analytics title={"Our Promo - drmo store"}></Analytics>
      <Nav active="home"></Nav>
      <div className="container">
        <div className="section section-promo" id="list-product">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Our Promo
              </li>
            </ol>
          </nav>
          <div className="title-section text-center">Our Promo</div>

          {onPageLoad && (['', '', ''].map((value, index) => (
            <div className="row align-items-center mt-3" key={`skeletinpromom${index}`}>
              <div className="col-12 col-md-4 text-start position-relative">
                <Skeleton className="img-promo"></Skeleton>
                <div className="content-wrapper">
                  <div className="title-section text-center text-uppercase">
                    <Skeleton width={150} height={35}></Skeleton>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="list-item  mt-5">
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    draggable
                    infinite
                    focusOnSelect={false}>
                    {["", "", "", "", ""].map((e, i) => (
                      <SekeletonCardProduct
                        key={`best-deal${i}`}></SekeletonCardProduct>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          )))}
          {!onPageLoad && ourPromo.length > 0 && (ourPromo.map((item, indexitem) => (
            <div className="row align-items-center mt-3" key={`asdvvk${indexitem}`}>
              <div className="col-12 col-md-4 text-start position-relative">
                <img src={imageUrl.product(item.thumbnail ?? 'img-promo-d.png')} alt={`thumbnail${indexitem}`} className="img-promo"></img>
                <div className="content-wrapper">
                  <div className="title-section text-center text-uppercase">
                    {item.nama}
                  </div>
                  <div className="text-center">
                    <Link
                      to={`/product?_a=${item.hash}`}
                      className="btn btn-see-all secondary font-500 mt-4">
                      See All
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="list-item mt-5">
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    itemClass={"me-2"}
                    focusOnSelect={false}>
                    {item.list.map((value, index) => (
                      <CardProduct
                        key={`bestdeal${index}`}
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url+'?_a='+item.hash}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          )))}
          {/*
          <div className="row align-items-center mt-3">
            <div className="col-12 col-md-4 text-start position-relative">
              <img src={imgPromo} alt={imgPromo} className="img-promo"></img>
              <div className="content-wrapper">
                <div className="title-section text-center text-uppercase">
                  BIRTHDAY Discount
                </div>
                <div className="text-center">
                  <Link
                    to="/list-promo"
                    className="btn btn-see-all secondary font-500 mt-4">
                    See All
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="list-item  mt-5">
                {onPageLoad ? (
                  <>
                    <Carousel
                      responsive={responsive}
                      showDots={false}
                      arrows={false}
                      draggable
                      infinite
                      focusOnSelect={false}>
                      {["", "", "", "", ""].map((e, i) => (
                        <SekeletonCardProduct
                          key={`best-deal${i}`}></SekeletonCardProduct>
                      ))}
                    </Carousel>
                  </>
                ) : (
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={true}
                    draggable={false}
                    infinite={true}
                    focusOnSelect={false}>
                    {listPromo.map((value, index) => (
                      <CardProduct
                        key={`bestdeal${index}`}
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={value.url}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
