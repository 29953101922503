import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/login/login.css";
import banner from "../assets/img/img-banner.png";
import LogoBrand from "../assets/img/logo-hitam.png";

export function ForgotPassword() {
  return (
    <>
      <section className="login">
        <div className="row ">
          <div className="col-md-6 d-table col-content">
            <div className=" d-table-cell align-middle content">
              <div className="card">
                <div className="logo-brand">
                  <img src={LogoBrand} alt={LogoBrand} className=""></img>
                </div>
                <div className="title-form">
                  <div className="welcome-text">Forget Your Password?</div>
                  <div className="desc-text">
                    Enter yoru email below to receive password reset
                    instructions.
                  </div>
                </div>
                <form
                  className="needs-validation mt-4"
                  method="POST"
                  id="LoginForm">
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <button
                    className="btn-black mt-4 w-100"
                    id="submitForm"
                    type="submit">
                    <span className="btn-login">Submit</span>
                  </button>
                  <div className="mt-3">
                    <svg
                      className="me-2"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.8333 10.5H4.16667"
                        stroke="#25282B"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 16.3334L4.16667 10.5001L10 4.66675"
                        stroke="#25282B"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Link to="/login">
                      <span style={{ fontSize: "16px" }}>Back to Log In</span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-table col-banner">
            <div className=" banner d-table-cell align-middle">
              <div className="text-banner">
                <div className="title">
                  Unlock Your Access to Cutting-Edge <br></br> Multimedia
                  Equipment at Drmo Store.
                </div>
                <div className="sub-title">Log in to Explore Excellence!</div>
              </div>
              <img src={banner} alt={banner} className="img-banner"></img>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
