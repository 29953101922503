
import star from "../../../assets/img/icon/star.svg";
import starOutline from "../../../assets/img/icon/star-outline.svg";
import iconCheckout from "../../../assets/img/icon/icon-checkout.svg";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../helpers";

const CardProduct = (props) => {
    const { discount, category, name, image, rating, review, originalPrice, discountPrice, url, onClick } = props;
    const checkSatuan = (string) => {
      if (string && string.toString().includes('%')) {
        let numberValue = parseFloat(string) || 0; // Handle non-numeric input
        let formattedPercentage = numberValue.toString() + "%";
        return formattedPercentage;
      }

      return string.replace('Rp', 'Rp ');
    }
    return (
      <Link to={url ? `/product/${url}` : `/product/detail`} onClick={(e) => {
        if(typeof onClick == "function") {
          onClick();
        }
      }}>
        <div className="item">
        {discountPrice ? <div className="discount">Disc. {checkSatuan(discount)}</div> : '' }
        <div className="text-center">
          <img
            src={image}
            alt={image}
            className="img-product"></img>
        </div>
        <div className="text-product">
          <div className="category">{category}</div>
          <div className="name">{name}</div>
          <div className="d-flex align-items-center mt-2">
            <img src={star} alt={star} className="star"></img>
            <div className="" style={{fontSize:"14px", color: "#9a9a9a"}}>{rating ?? 0}</div>
            <div className="line">|</div>
            <div className="total-review">{review} Reviews</div>
          </div>
          <div className="d-flex price align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap">
            {discountPrice ? 
            <>
            <div className="original-price">{`Rp ${formatMoney(originalPrice)}`}</div>
            <div className="discount-price">{`Rp ${formatMoney(discountPrice)}`}</div>
            </>
            :
            <div className="discount-price black">{`Rp ${formatMoney(originalPrice)}`}</div>
            }
            </div>
            {/* <img
              src={iconCheckout}
              alt={iconCheckout}
              className=""></img> */}
          </div>
        </div>
      </div>
      </Link>
    );
  };
  
  export default CardProduct;
  