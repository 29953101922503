import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imgProduct1 from "../../assets/img/img-product-1.png";
import iconArrow from "../../assets/img/icon/icon-arrow.svg";
import "../../assets/scss/home/home.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";
import { useMediaQuery } from "react-responsive";

export function Category({ list, loading }) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 4,
    },
    mobile: {
      centerMode: true,
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="container">
        <div className="" id="category">
          {isDesktopScreen && (
            <>
              {loading ? (
                <div className="list-item d-flex justify-content-center">
                  {["", "", "", "", "", ""].map((i, e) => (
                    <div
                      key={`skeleton_bestseller${e}`}
                      className={`item-category d-flex justify-content-center align-items-center ${
                        e == 0 ? "active" : ""
                      }`}>
                      <Skeleton
                        width={"115px"}
                        height={"115px"}
                        borderRadius={5}></Skeleton>
                      <div className="text-product">
                        <div className="best">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                        <div className="title">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                        <div className="view-all">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="list-item d-flex justify-content-center">
                  {list.map((value, index) => (
                    <div
                      key={`bestseller${index}`}
                      className={`item-category  d-flex justify-content-center align-items-center ${
                        index == 0 ? "active" : ""
                      }`}>
                      <img
                        src={imageUrl.product(value.gambar)}
                        alt={imageUrl.product(value.gambar)}
                        className="img-product"></img>
                      <div className="text-product">
                        <div className="best">Best Sellers</div>
                        <div className="title">{value.nama}</div>
                        <Link to={"/best-seller"} style={{ color: "unset" }}>
                          <div className="view-all">
                            View all
                            <svg
                              className="icon-arrow"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_345_15156)">
                                <circle
                                  cx="11"
                                  cy="11"
                                  r="10.25"
                                  fill="white"
                                  stroke="#1F1F23"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M10 15L14 11L10 7"
                                  stroke="#1F1F23"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_345_15156">
                                  <rect width="22" height="22" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {isPhoneScreen && (
            <>
              {loading ? (
                <div className="list-item d-flex justify-content-center">
                  {["", "", "", "", "", ""].map((i, e) => (
                    <div
                      key={`skeleton_bestseller${e}`}
                      className={`item-category d-flex justify-content-center align-items-center ${
                        e == 0 ? "active" : ""
                      }`}>
                      <Skeleton
                        width={"115px"}
                        height={"115px"}
                        borderRadius={5}></Skeleton>
                      <div className="text-product">
                        <div className="best">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                        <div className="title">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                        <div className="view-all">
                          <Skeleton width={"100px"}></Skeleton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
               
                  <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    autoPlay={false}>
                    {list.map((value, index) => (
                      <div
                        key={`bestseller${index}`}
                        className={`item-category  d-flex justify-content-center align-items-center ${
                          index == 0 ? "active" : ""
                        }`}>
                        <img
                          src={imageUrl.product(value.gambar)}
                          alt={imageUrl.product(value.gambar)}
                          className="img-product"></img>
                        <div className="text-product">
                          <div className="best">Best Sellers</div>
                          <div className="title">{value.nama}</div>
                          <Link to={"/best-seller"} style={{ color: "unset" }}>
                            <div className="view-all">
                              View all
                              <svg
                                className="icon-arrow"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_345_15156)">
                                  <circle
                                    cx="11"
                                    cy="11"
                                    r="10.25"
                                    fill="white"
                                    stroke="#1F1F23"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M10 15L14 11L10 7"
                                    stroke="#1F1F23"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_345_15156">
                                    <rect width="22" height="22" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Carousel>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
