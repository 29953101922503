

import { Helmet } from 'react-helmet';
import logo192 from '../assets/img/logo192.png'
import { formatDate } from '../helpers';
const defaultMetaTag = {
    title: 'drmo store',
    description: "drmo store",
    keyword: "marketplace,camera",
    language: "en",
    url: window.location.origin,
    image: logo192,
    site_name: 'drmo store',
    date: ''
};

export default function MetaTags (props) {
    const metaTag = { ...defaultMetaTag, ...props };

    return (
        <>
            <Helmet>
                <title>{metaTag.title}</title>
                <meta name='keywords' content={metaTag.keyword} />
                <meta name='description' content={metaTag.description} />
                <meta name='robots' content='index,follow' />
                <meta name='url' content={metaTag.url} />
                <meta name='identifier-URL' content={metaTag.url} />

                <meta name='og:title' content={metaTag.title} />
                <meta name='og:type' content='website' />
                <meta name='og:url' content={metaTag.url} />
                <meta name='og:image' content={metaTag.image} />
                <meta name='og:site_name' content={metaTag.site_name} />
                <meta name='og:description' content={metaTag.description} />

                <meta name="twitter:title" content={metaTag.title} />
                <meta name="twitter:description" content={metaTag.description} />
                <meta name="twitter:image" content={metaTag.image} />

                <meta name='date' content={formatDate(metaTag.date, 'YYYY-MM-DD')} />
                <meta name='search_date' content={formatDate(metaTag.date, 'YYYY-MM-DD')}/>
            </Helmet>
        </>
    )
}