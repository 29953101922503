import React, { Profiler, useState, useEffect } from "react";
import "../assets/scss/nav/nav.css";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import NavCategory from "../components/element/NavCategory";
import NavBrand from "../components/element/NavBrand";
import Carousel from "react-multi-carousel";
import iconBrand from "../assets/img/logo-hitam.png";
import LogoBrand from "../assets/img/logo-hitam.png";
import eyeOn from "../assets/img/icon/eye-on.svg";
import eyeOff from "../assets/img/icon/eye-off.svg";
import iconCart from "../assets/img/icon/icon-cart.svg";

import review from "../assets/img/img-review.png";
import { cksClient } from "../helpers";
import { useMediaQuery } from "react-responsive";
import { imageUrl } from "../actions/image";

export function Nav({ active, onChangeKeyword, onclickBrand, onclickCategory }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [modalCategory, setModalCategory] = useState(false);
  const [modalBrand, setModalBrand] = useState(false);
  const [firstLoad, setfirstLoad] = useState(false)
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [brandProduct, setBrandProduct] = useState([]);
  const [itemAccesories, setItemAccesories] = useState({
    url: ''
  })

  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const [keyword, setKeyword] = useState()

  const checkAccsesoris = (category) => {
    var check = category.find((e) => {
      var check = e.nama.toString().toLowerCase().includes('accessories') || e.nama.toString().toLowerCase().includes('accessoris') || e.nama.toString().toLowerCase().includes('aksesoris')
      return check;
    })
    if (check) {
      setItemAccesories(check);
    }
  }

  useEffect(() => {
    var intervalCheckCategory, intervalCheckBrand;
    intervalCheckCategory = setInterval(() => {
      const category = localStorage.getItem('_ctg');
      if (category) {
        setCategoryProduct(JSON.parse(category));
        checkAccsesoris(JSON.parse(category));
        clearInterval(intervalCheckCategory);
      }
    }, 20)

    intervalCheckBrand = setInterval(() => {
      const brand = localStorage.getItem('_brd');
      if (brand) {
        setBrandProduct(JSON.parse(brand));
        clearInterval(intervalCheckBrand);
      }
    }, 20)
  }, []);

  const onSearch = (e, key) => {
    if (keyword) {
      if (location.pathname == "/blog") {
        onChangeKeyword(key == 'not' ? '' : keyword)
        // navigate(`/blog?keyword=${key == 'not' ? '' : keyword}`)
      } else if (location.pathname == "/product" || location.pathname == "/best_seller") {
        onChangeKeyword(key == 'not' ? '' : keyword)
        navigate(`${location.pathname}?keyword=${key == 'not' ? '' : keyword}`)
      } else {
        navigate(`/product?keyword=${key == 'not' ? '' : keyword}`)
      }
    }
    e.preventDefault();
  }

  return (
    <>
      <div className="">
        <nav className={`navbar navbar-expand-lg`}>
          <div className="d-flex align-items-center w-100 py-2 container">
            <Link
              variant="navbar-brand text-start d-flex align-items-center"
              to="/">
              <img src={iconBrand} alt={iconBrand} className="icon-brand"></img>
            </Link>
            <button
              onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
              <div className="wrapper-navbar me-auto ms-3">
                <div className="navbar-nav mb-2 mb-lg-0 container">
                  <div className="nav-item position-relative nav-item-category">
                    {isPhoneScreen ?
                      <div className="nav-link" onClick={() => {
                        setModalCategory(true);
                        // if (typeof onclickCategory == 'function') {
                        setIsNavExpanded(!isNavExpanded)
                        // onclickCategory('clear-all')
                        // }
                      }}>Category</div>
                      :
                      <div className="nav-link">
                        Category
                      </div>
                    }
                    {isDesktopScreen ?
                      <div className="nav-category wrapper-nav">
                        <div className="wrapper-content-nav">
                          <div className="container">
                            <NavCategory list={categoryProduct} onchange={e => {
                              if (typeof onclickCategory == 'function') {
                                onclickCategory(e);
                              }
                            }}></NavCategory>
                          </div>
                        </div>
                      </div>
                      : ''}
                  </div>
                  <li className="nav-item position-relative nav-item-brand">
                    {isPhoneScreen ?
                      <div className="nav-link" onClick={() => {
                        setModalBrand(true);
                        setIsNavExpanded(!isNavExpanded)
                      }}>Brand</div>
                      :
                      <div className="nav-link">
                        Brand
                      </div>
                    }
                    {isDesktopScreen ?
                      <div className="nav-brand wrapper-nav">
                        <div className="wrapper-content-nav">
                          <div className="container">
                            <NavBrand list={brandProduct} onchange={e => {
                              if (typeof onclickBrand == 'function') {
                                onclickBrand(e);
                              }
                            }}></NavBrand>
                          </div>
                        </div>
                      </div>
                      : ''}
                  </li>
                  <li className="nav-item">
                    <Link to={`/product`} className={`nav-link ${active == 'product' ? 'active' : ''}`}>
                      Product
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/blog" className={`nav-link ${active == 'blog' ? 'active' : ''}`}>
                      Blog
                    </Link>
                  </li>
                </div>
              </div>

              <div className="input-group">
                <span className="input-group-text">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.5 17.5L12.5 12.5M2.5 8.33333C2.5 9.09938 2.65088 9.85792 2.94404 10.5657C3.23719 11.2734 3.66687 11.9164 4.20854 12.4581C4.75022 12.9998 5.39328 13.4295 6.10101 13.7226C6.80875 14.0158 7.56729 14.1667 8.33333 14.1667C9.09938 14.1667 9.85792 14.0158 10.5657 13.7226C11.2734 13.4295 11.9164 12.9998 12.4581 12.4581C12.9998 11.9164 13.4295 11.2734 13.7226 10.5657C14.0158 9.85792 14.1667 9.09938 14.1667 8.33333C14.1667 7.56729 14.0158 6.80875 13.7226 6.10101C13.4295 5.39328 12.9998 4.75022 12.4581 4.20854C11.9164 3.66687 11.2734 3.23719 10.5657 2.94404C9.85792 2.65088 9.09938 2.5 8.33333 2.5C7.56729 2.5 6.80875 2.65088 6.10101 2.94404C5.39328 3.23719 4.75022 3.66687 4.20854 4.20854C3.66687 4.75022 3.23719 5.39328 2.94404 6.10101C2.65088 6.80875 2.5 7.56729 2.5 8.33333Z"
                      stroke="#B1B2BD"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <form onSubmit={(e) => {
                  onSearch(e, '')
                }}>
                  <input
                    type="text"
                    name="name"
                    className="form-control input-search"
                    placeholder="Search"
                    value={keyword}
                    required={false}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                      if (e.target.value == '') {
                        onSearch(e, 'not');
                      }
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {modalCategory && <div className="modal-backdrop"></div>}
      {modalCategory && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "592px" }}>
            <div className="modal-content">
              <button
                onClick={(e) => setModalCategory(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button">
                <svg
                  className="close-modal"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="modal-body">
                <div className="list-filter">
                  <div className="wrapper-filter">
                    <div className="title">Category</div>
                    <div className="row">
                      {categoryProduct.map((value, index) => (
                        <>
                        <div className="col-6 text-center mt-4">
                          <Link style={{ color: 'unset' }} to={`/product?category=${value.url}`} onClick={() => {
                            if (typeof onclickCategory == 'function') {
                              setModalCategory(false)
                              onclickCategory([value.url])
                            }
                          }}>
                            <img src={imageUrl.product_category(value.gambar)} style={{ 
                              width: '60%',
                              height: '100px',
                              objectFit: 'contain'
                             }}></img>
                            <div style={{ 
                              marginTop: '10px'
                             }}>{value.nama}</div>
                          </Link>
                        </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalBrand && <div className="modal-backdrop"></div>}
      {modalBrand && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "592px" }}>
            <div className="modal-content">
              <button
                onClick={(e) => setModalBrand(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button">
                <svg
                  className="close-modal"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="modal-body">
                <div className="list-filter">
                  <div className="wrapper-filter">
                    <div className="title">Brand</div>
                    <div className="row">
                      {brandProduct.map((value, index) => (
                        <>
                        <div className="col-6 text-center mt-4">
                          <Link style={{ color: 'unset' }} to={`/product?brand=${value.url}`} onClick={() => {
                            if (typeof onclickBrand == 'function') {
                              setModalBrand(false)
                              onclickBrand([value.url])
                            }
                          }}>
                            <img src={imageUrl.product_brand(value.gambar)} style={{ 
                              width: '60%',
                              height: '100px',
                              objectFit: 'contain'
                             }}></img>
                            {/* <div style={{ 
                              marginTop: '10px'
                             }}>{value.nama}</div> */}
                          </Link>
                        </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
