
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import lens from "../../assets/img/lens.png";
import lighting from "../../assets/img/lighting.png";
import drone from "../../assets/img/drone.png";
import { imageUrl } from "../../actions/image";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const NavCategory = ({list, onchange}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Slider
      {...settings}
        >
        {list.map((value, index) => (
          <div className="" key={`navcategory-${index}`} >
            <Link to={`/product?category=${value.url}`} style={{ color: 'unset' }} onClick={() => {
              onchange([value.url])
            }}>
              <div className="item-brand">
                <img src={imageUrl.product_category(value.gambar)} alt={imageUrl.product_category(value.gambar)}></img>
                <div className="text">{value.nama}</div>
              </div>
            </Link>
          </div>
        ))}
        {/* <div className="">
          <div className="item-brand">
            <img src={drone} alt={drone}></img>
            <div className="text">Lens</div>
          </div>
        </div>
        <div className="">
          <div className="item-category">
            <img src={lighting} alt={lighting}></img>
            <div className="text">Lens</div>
          </div>
        </div>
        <div className="">
          <div className="item-category">
            <img src={lens} alt={lens}></img>
            <div className="text">Lens</div>
          </div>
        </div>
        <div className="">
          <div className="item-category">
            <img src={lens} alt={lens}></img>
            <div className="text">Lens</div>
          </div>
        </div>
        <div className="">
          <div className="item-category">
            <img src={lens} alt={lens}></img>
            <div className="text">Lens</div>
          </div>
        </div>
        <div className="">
          <div className="item-category">
            <img src={lens} alt={lens}></img>
            <div className="text">Lens</div>
          </div>
        </div> */}
      </Slider>
    </div>
  );
};

export default NavCategory;
