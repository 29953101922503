import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export default function Maps({coordinate}) {
  const [center, setCenter] = useState({
    lat: -6.175237678515803,
    lng: 106.82712676006798,
  });


  useEffect(() => {
    var split = coordinate.split(',')
    setCenter({
        lat: parseFloat(split[0]),
        lng: parseFloat(split[1]),
    });
  }, [coordinate]);
  


  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCVcBFOy1d-5h_VLjgIRZWYvz4Kxa2W1W4",
  });

  const [map, setMap] = React.useState(null);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} zoom={14} center={center}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}
