import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../assets/scss/home/home.css";
import brand1 from "../../assets/img/img-brand-1.png";
import brand2 from "../../assets/img/img-brand-2.png";
import brand3 from "../../assets/img/img-brand-3.png";
import brand4 from "../../assets/img/img-brand-4.png";
import brand5 from "../../assets/img/img-brand-5.png";
import brand6 from "../../assets/img/img-brand-6.png";
import brand7 from "../../assets/img/img-brand-7.png";
import brand8 from "../../assets/img/img-brand-8.png";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function Brand({list, loading}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 4,
    },
    mobile: {
      centerMode: true,
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="container">
        <div className="" id="brand">
          <div className="">
            {loading ?
            <> 
            <Carousel responsive={responsive} showDots={false} arrows={false}  autoPlay={false}
            infinite
            draggable>
              {['', '', '', '', '', ''].map((e, i) => (
                <div className="item-brand">
                  <Skeleton width={200} height={100} borderRadius={5}></Skeleton>
                </div>
              ))}
            </Carousel>
            </>
            : <Carousel responsive={responsive} showDots={false} arrows={false}  autoPlay={true}
            autoPlaySpeed={2000}
            infinite
            draggable>
              {list.map((value, index) => (
              <div className="item-brand" key={`brand-${index}`}>
                <img src={imageUrl.product_brand(value.gambar)} alt={imageUrl.product_brand(value.gambar)} className="img-brand"></img>
              </div>
              ))}
            </Carousel>}
          </div>
        </div>
      </div>
    </>
  );
}
