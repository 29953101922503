import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import brand2 from "../../assets/img/img-brand-2.png";
import brand1 from "../../assets/img/img-brand-3.png";
import brand3 from "../../assets/img/img-brand-4.png";
import brand4 from "../../assets/img/img-brand-1.png";
import brand5 from "../../assets/img/img-brand-5.png";
import { imageUrl } from "../../actions/image";
import { Link } from "react-router-dom";

const NavBrand = ({list, onchange}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {list.map((value, index) => (
        <div className="item-brand mt-3" key={`brandnav${index}`} onClick={() => {
          onchange([value.url])
        }}>
          <Link to={`/product?brand=${value.url}`} style={{ color: 'unset' }}>
            <img src={imageUrl.product_brand(value.gambar)} alt={imageUrl.product_brand(value.gambar)}></img>
          </Link>
        </div>
      ))}
      {/* <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div> */}
      {/* <div className="d-flex justify-content-center wrapper-item px-5">
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand3} alt={brand3}></img>
        </div>
        <div className="item-brand">
          <img src={brand4} alt={brand4}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand1} alt={brand1}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
    </div> */}
      {/* <div className="d-flex justify-content-center wrapper-item px-5">
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand3} alt={brand3}></img>
        </div>
        <div className="item-brand">
          <img src={brand4} alt={brand4}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand1} alt={brand1}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
      <div className="">
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
        <div className="item-brand">
          <img src={brand2} alt={brand2}></img>
        </div>
      </div>
    </div> */}
    </Slider>
  );
};

export default NavBrand;
