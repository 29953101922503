import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const Analytics = ({ title }) => {
    var [firstLoad, setFirstLoad] = useState(true); 
    // Extracts pathname property(key) from an object
    const history = useLocation();
    // Automatically scrolls to top whenever pathname changes
    if (firstLoad) {
        ReactGA.send({
            hitType: "pageview", page: history.pathname, title: title,
        });
        setFirstLoad(false);
    }
};

export default Analytics;
