import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/blog/blog.css";
import CardBlog from "../components/element/CardBlog";
import news4 from "../assets/img/news-4.png";
import { get_BlogCategory, get_BlogList } from "../actions";
import Skeleton from "react-loading-skeleton";
import SkeletonCardBlog from "../components/element/CardBlogSkeleton";
import { imageUrl } from "../actions/image";
import { formatDate } from "../helpers";
import emptyBlog from '../assets/img/empty-blog.svg';
import MetaTags from "../helpers/MetaTags";
import Analytics from "../helpers/Analytics";

export function ListBlog() {
  const location = useLocation();
  const sectionRef = useRef();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(location.search);
  const [listCategory, setListCategory] = useState([]);
  const [listBlog, setListBlog] = useState([]);
  const [onLoadCategory, setonLoadCategory] = useState(true);
  const [onLoadBlog, setonLoadBlog] = useState(true);
  const [filterCategory, setFilterCategory] = useState(urlSearchParams.get('category') ?? '');
  const [pagination, setPaging] = useState({
    page: 1,
    totalPage: 1
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa:  ''
  })

  const changeKeyword = (e) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete('category')
    urlSearchParams.set('keyword', e);
    navigate('/blog?' + urlSearchParams.toString())
    getListBlog(1, '', e);
  }

  const getListBlog = (page = 1, kategori = '', keyword = '') => {
    const urlSearchParams = new URLSearchParams(location.search);
    setonLoadBlog(true)

    get_BlogList({
      keyword: keyword,
      kategori: kategori,
      page: page,
    }, (res) => {
      const { last_page, current_page, data } = res.list
      if (page == 1) {
        setListBlog(data);
      } else {
        setListBlog([...listBlog, ...data]);
      }
      setPaging({
        page: current_page,
        totalPage: last_page
      })
      setonLoadBlog(false)
    })
  }

  const setCategory = (url) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('category', url);
    urlSearchParams.delete('keyword')

    setFilterCategory(url);

    // console.log(urlSearchParams.toString());

    navigate('/blog?' + urlSearchParams.toString())
    getListBlog(1, url, '');
  }


  useEffect(() => {

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({...metaData, ...(JSON.parse(meta))});
        clearInterval(intervalCheckMeta);
      }
    }, 20)

    const urlSearchParams = new URLSearchParams(location.search);
    console.log(filterCategory);
    setonLoadCategory(true);
    get_BlogCategory(res => {
      const { list } = res
      setListCategory(list);
      setonLoadCategory(false);
    })

    getListBlog(1, urlSearchParams.get('category'), urlSearchParams.get('keyword'));
  }, [])

  return (
    <>
      <MetaTags title={ 'Blog - drmo store'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <Nav active="blog" onChangeKeyword={changeKeyword}></Nav>
      <Analytics title={'Blog - drmo store'}></Analytics>

      <div className="container">
        <div className="section" id="list-blog" ref={sectionRef}>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Blog
              </li>
            </ol>
          </nav>
          <div className="title-section text-center">Blog</div>
          <div className="row mt-5">
            <div className="col-md-10 col-12">
              <div className="row">
                {listBlog.length > 0 && !onLoadBlog ? listBlog.map((value, index) => (
                  <div className="col-md-4 col-12">
                    <Link to={`/blog/${value.url}`}>
                      <CardBlog
                        image={imageUrl.blog(value.gambar)}
                        category={value.kategory}
                        name={value.judul}
                        desc={value.isi}
                        date={formatDate(value.created_at)}></CardBlog>
                    </Link>
                  </div>
                )) : ''
                }

                {onLoadBlog ?
                  ['', '', '', '', '', ''].map((i, e) => (
                    <div className="col-md-4 col-12">
                      <SkeletonCardBlog key={`skeletonblog${e}`}></SkeletonCardBlog>
                    </div>
                  ))
                  : ''}

              </div>

              {!onLoadBlog && listBlog.length == 0 ?
                <div className="text-center my-5">
                  <img src={emptyBlog} height={100}
                    alt="empty-review"></img>
                  <div style={{ color: '#9A9A9A', fontSize: '1rem' }} className="mt-4">No content found</div>
                </div>
                : ''}
              {!onLoadBlog && pagination.totalPage && pagination.page != pagination.totalPage ?
                <div className="text-center">
                  <button type="button" style={{
                    padding: "8px 16px",
                    border: '1px solid #979797'
                  }} className="btn" onClick={(e) => {
                    const urlSearchParams = new URLSearchParams(location.search);
                    getListBlog(pagination.page + 1, urlSearchParams.get('category'), urlSearchParams.get('keyword'))
                  }}>Load More</button>
                </div>
                : ''}
            </div>
            <div className="col-md-2 col-12">
              <div className="filter">
                <div className="title-filter">Category</div>
                {onLoadCategory ?
                  <Skeleton width={160} height={24} borderRadius={2} count={5}></Skeleton>
                  :
                  <>
                    {listCategory.map((value, index) => (
                      <div className={`item-filter ${value.kategori == filterCategory ? 'active' : ''}`} key={`blog-category${index}`} onClick={(e) => setCategory(value.kategori)}>{value.kategori} ({value.total})</div>
                    ))}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
