import { convertIsi } from "../../helpers";

const CardBlog = (props) => {
  const { category, name, image, date, desc } = props;
  return (
    <div className="item-blog">
      <img src={image} alt={image} className="img-blog"></img>
      <div className="text-product">
        <div className="category">{category}</div>
        <div className="name">{name}</div>
        <div className="desc">{convertIsi(desc)}</div>
        <div className="date">{date}</div>
      </div>
    </div>
  );
};

export default CardBlog;
