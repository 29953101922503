import React, { useEffect, useState } from "react";
import { Link, useFetcher, useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import "../assets/scss/list_product/list_product.css";
import imgProduct2 from "../assets/img/img-product-2.png";
import iconFilter from "../assets/img/icon/icon-filter.svg";
import CardProduct from "../components/element/CardProduct/CardProduct";
import SekeletonCardProduct from "../components/element/CardProduct/CardProductSekeleton";
import { get_BrandList, get_CategoryList, get_ProductList } from "../actions";
import { imageUrl } from "../actions/image";
import productEmpty from '../assets/img/empty-product.svg'
import MetaTags from "../helpers/MetaTags";
import { cryptoJs } from "../helpers";
import Analytics from "../helpers/Analytics";

export function ListProduct() {
  const location = useLocation();
  const [modalBackdrop, setBackdrop] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [onPageLoad, setPageLoad] = useState(true);
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const toggleModal = (e, value) => {
    setModalFilter(!modalFilter);
    setBackdrop(!modalBackdrop);
  };
  const [productList, setProductList] = useState([]);
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [brandProduct, setBrandProduct] = useState([]);
  const [timeoutFilter, setTimoutFilter] = useState('')
  const [sortFilter, setSortFilter] = useState({
    value: "a-z",
    label: "A - Z",
  })
  const [pagination, setPaging] = useState({
    currentPage: 1,
    totalPage: 1,
    total: 0,
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: '',
  })

  const listFilter = [
    {
      value: "a-z",
      label: "A - Z",
    },
    {
      value: "z-a",
      label: "Z - A",
    },
    {
      value: "price high-low",
      label: "Price, High to Low",
    },
    {
      value: "price low-high",
      label: "Price, Low to High",
    },
  ];

  const [filter, setFilter] = useState({
    price: 'all',
    brand: [],
    category: [],
    sort: 'a-z',
    is_best_seller: 'tidak',
    min: '',
    max: '',
    immediately: false,
    load: false,
    promoId: "",
  })

  const [itemPromo, setItemPromo] = useState({
    id: '',
    nama: '',
    exist: false,
    hash: '',
  })

  const [filterMobile, setFilterMobile] = useState({
    price: 'all',
    brand: [],
    category: [],
    min: '',
    max: '',
  })

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        textAlign: "left",
      };
    },
  };

  const getList = (page = 1) => {
    var filterPrice = {
      "all": {
        min: '',
        max: '',
      },
      "< 1 Juta": {
        min: '',
        max: 1000000,
      },
      "1 Juta - 15 Juta": {
        min: 1000000,
        max: 15000000,
      },
      "15 Juta - 50 Juta": {
        min: 15000000,
        max: 50000000,
      },
      "> 50 Juta": {
        min: 50000000,
        max: '',
      },
    }

    const filterFix = { ...filter, min: filterPrice[filter.price].min, max: filterPrice[filter.price].max, page: page }
    if (page == 1) {
      setProductList([]);
    }
    setPageLoad(true)
    get_ProductList(filterFix, (res) => {
      const { list, paging } = res;
      if (page == 1) {
        setProductList(list);
      } else {
        setProductList([...productList, ...list]);
      }
      setPaging(paging)
      setPageLoad(false)
    })
  }

  useEffect(() => {

    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)

    const urlSearchParams = new URLSearchParams(location.search);
    var promoId = '';
    if (urlSearchParams.get('_a')) {
      try {
        var decrypt = cryptoJs('decrypt', urlSearchParams.get('_a'));
        var promo = JSON.parse(decrypt);
        promoId = promo.id;
        setItemPromo({ ...itemPromo, ...promo, exist: true, hash: urlSearchParams.get('_a') }); 
      } catch (error) {
        setItemPromo({ ...itemPromo, exist: false });  
      }
    } else {
      setItemPromo({ ...itemPromo, exist: false });
    }

    get_CategoryList({
      promoId: promoId,
    }, (res) => {
      const {list} = res;
      setCategoryProduct(list)
    });

    get_BrandList({
      promoId: promoId,
    }, (res) => {
      const {list} = res;
      setBrandProduct(list)
    });

    setFilter({ ...filter, category: urlSearchParams.getAll('category'), brand: urlSearchParams.getAll('brand'), is_best_seller: location.pathname == '/best-seller' ? 'ya' : 'tidak', immediately: true, load: true, promoId: promoId, })

    setFilterMobile({...filterMobile, category: urlSearchParams.getAll('category'), brand: urlSearchParams.getAll('brand'),})
    // getList()
  }, [])

  useEffect(() => {
    if (filter.load) {
      if (filter.immediately) {
        getList();
      } else {
        if (timeoutFilter) {
          clearTimeout(timeoutFilter);
        }
        var timeout = setTimeout(() => {
          getList();
        }, 1500);
        setTimoutFilter(timeout);
      }
    }
  }, [filter])

  return (
    <>
      <MetaTags title={itemPromo.exist && itemPromo.nama ? `${itemPromo.nama} - drmo store` :location.pathname == '/best-seller' ? 'Best Seller - drmo store' : location.pathname == '/product' ? 'Product - drmo store' : 'drmo store'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <Analytics title={itemPromo.exist && itemPromo.nama ? `${itemPromo.nama} - drmo store` :location.pathname == '/best-seller' ? 'Best Seller - drmo store' : location.pathname == '/product' ? 'Product - drmo store' : 'drmo store'}></Analytics>
      <Nav active="product" onChangeKeyword={(keyword) => {
        setSortFilter({
          value: "a-z",
          label: "A - Z",
        })
        setFilter({ ...filter, keyword: keyword, category: '', brand: '', is_best_seller: location.pathname == '/best-seller' ? 'ya' : 'tidak', price: 'all', sort: 'a-z', immediately: false, })

        setFilterMobile({ ...filterMobile, category: '', brand: '', price: 'all' })
      }} onclickBrand={(e) => {
        setSortFilter({
          value: "a-z",
          label: "A - Z",
        })
        setFilter({ ...filter, keyword: '', category: '', brand: e != 'clear-all' ? e : '', is_best_seller: location.pathname == '/best-seller' ? 'ya' : 'tidak', price: 'all', sort: 'a-z', immediately: false, })

        setFilterMobile({ ...filterMobile, category: '', brand: e != 'clear-all' ? e : '', price: 'all' })
      }} onclickCategory={(e) => {
        setSortFilter({
          value: "a-z",
          label: "A - Z",
        })
        setFilter({ ...filter, keyword: '', category: e != 'clear-all' ? e : '', brand: '', is_best_seller: location.pathname == '/best-seller' ? 'ya' : 'tidak', price: 'all', sort: 'a-z', immediately: false, })

        setFilterMobile({ ...filterMobile, category: e != 'clear-all' ? e : '', brand: '', price: 'all' })
      }}></Nav>
      {modalBackdrop && <div className="modal-backdrop"></div>}
      {modalFilter && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "592px" }}>
            <div className="modal-content">
              <button
                onClick={toggleModal}
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button">
                <svg
                  className="close-modal"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#25282B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <div className="modal-body">
                <div className="list-filter">
                  <div className="wrapper-filter">
                    <div className="title">Price</div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"all"}
                          checked={filterMobile.price == "all"}
                          onChange={(e) => setFilterMobile({ ...filterMobile, price: e.target.value })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>All Price</span>
                        </div>
                      </label>
                    </div>

                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"< 1 Juta"}
                          checked={filterMobile.price == "< 1 Juta"}
                          onChange={(e) => setFilterMobile({ ...filterMobile, price: e.target.value })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{"< 1 Juta"}</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"1 Juta - 15 Juta"}
                          checked={filterMobile.price == "1 Juta - 15 Juta"}
                          onChange={(e) => setFilterMobile({ ...filterMobile, price: e.target.value })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>1 Juta - 15 Juta</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"15 Juta - 50 Juta"}
                          checked={filterMobile.price == "15 Juta - 50 Juta"}
                          onChange={(e) => setFilterMobile({ ...filterMobile, price: e.target.value })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>15 Juta - 50 Juta</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"> 50 Juta"}
                          checked={filterMobile.price == "> 50 Juta"}
                          onChange={(e) => setFilterMobile({ ...filterMobile, price: e.target.value })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{"> 50 Juta"}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="wrapper-filter">
                    <div className="title">Brand</div>
                    {brandProduct.map((value, index) => (
                      <div className="item-filter d-flex justify-content-between align-items-center">
                        <label className="d-flex">
                          <input type="checkbox" name="brand" value={value.url} checked={filterMobile.brand.includes(value.url)} onChange={(e) => {
                            var brand = filterMobile.brand;
                            var check = Array.isArray(brand) ? brand.findIndex((e) => e == value.url) : -1;
                            if (check < 0) {
                              brand.push(value.url)
                            } else {
                              brand.splice(check, 1)
                            }

                            setFilterMobile({ ...filterMobile, brand: brand })
                          }}></input>
                          <div className="me-3">
                            <span>{value.nama}</span>
                          </div>
                        </label>
                        <div className="total">{value.total}</div>
                      </div>
                    ))}
                  </div>
                  <hr></hr>
                  <div className="wrapper-filter">
                    <div className="title">Category</div>
                    {categoryProduct.map((value, index) => (
                      <div className="item-filter d-flex justify-content-between align-items-center">
                        <label className="d-flex">
                          <input type="checkbox" name="category" value={value.url} checked={filterMobile.category.includes(value.url)} onChange={(e) => {
                            var category = filterMobile.category;
                            var check = Array.isArray(category) ? category.findIndex((e) => e == value.url) : '';
                            if (check < 0) {
                              category.push(value.url)
                            } else {
                              category.splice(check, 1)
                            }

                            setFilterMobile({ ...filterMobile, category: category })
                          }}></input>
                          <div className="me-3">
                            <span>{value.nama}</span>
                          </div>
                        </label>
                        <div className="total">{value.total}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <button onClick={() => {
                  setFilter({ ...filter, brand: filterMobile.brand, category: filterMobile.category, price: filterMobile.price, immediately: true })
                  toggleModal();
                }} className="w-100 btn-black mt-0">Apply</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="section" id="list-product">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {itemPromo.exist && itemPromo.nama && (
                <>
                  <li className="breadcrumb-item">
                    <Link to="/our-promo">Our Promo</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {itemPromo.nama}
                  </li>
                </>
              )}
              {!itemPromo.exist && (
                <li className="breadcrumb-item active" aria-current="page">
                  List Product
                </li>
              )}
            </ol>
          </nav>
          <div className="title-section text-center">{location.pathname == '/best-seller' ? 'Best Seller' : itemPromo.exist && itemPromo.nama ? itemPromo.nama : 'Our Product'}</div>
          {isPhoneScreen && (
            <div className="row justify-content-between align-items-center mt-4">
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex btn-filter align-items-center"
                    onClick={() => {
                      setFilterMobile({ ...filterMobile, brand: filter.brand, category: filter.category, price: filter.price })
                      toggleModal();
                    }}>
                    <div className="">
                      <img src={iconFilter} alt={iconFilter}></img>
                    </div>
                    <div className="ms-2">Filter</div>
                  </div>
                  <div className="ms-2 text">{pagination.total} items</div>
                </div>
              </div>
              <div className="col-6 ">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="text me-2">Sort By:</div>
                  <div className="" style={{ width: "100px" }}>
                    <Select
                      options={listFilter}
                      defaultValue={listFilter[0]}
                      placeholder="Filter"
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={false}
                      value={sortFilter}
                      styles={colourStyles}
                      onChange={e => {
                        setSortFilter(e)
                        setFilter({ ...filter, sort: e.value, immediately: true })
                      }}>

                    </Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4">
            {isDesktopScreen && (
              <div className="col-md-3 col-12">
                <div className="list-filter">
                  <div className="wrapper-filter">
                    <div className="title">Price</div>

                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"all"}
                          checked={filter.price == "all"}
                          onChange={(e) => setFilter({ ...filter, price: e.target.value, immediately: false, })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>All Price</span>
                        </div>
                      </label>
                    </div>

                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"< 1 Juta"}
                          checked={filter.price == "< 1 Juta"}
                          onChange={(e) => setFilter({ ...filter, price: e.target.value, immediately: false, })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{"< 1 Juta"}</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"1 Juta - 15 Juta"}
                          checked={filter.price == "1 Juta - 15 Juta"}
                          onChange={(e) => setFilter({ ...filter, price: e.target.value, immediately: false, })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>1 Juta - 15 Juta</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"15 Juta - 50 Juta"}
                          checked={filter.price == "15 Juta - 50 Juta"}
                          onChange={(e) => setFilter({ ...filter, price: e.target.value, immediately: false })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>15 Juta - 50 Juta</span>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="mr-3">
                        <input
                          type="radio"
                          name="price"
                          value={"> 50 Juta"}
                          checked={filter.price == "> 50 Juta"}
                          onChange={(e) => setFilter({ ...filter, price: e.target.value, immediately: false })}
                        />
                        <div className="d-flex align-items-center me-3">
                          <span>{"> 50 Juta"}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="wrapper-filter">
                    <div className="title">Brand</div>
                    {brandProduct.map((value, index) => (
                      <div className="item-filter d-flex justify-content-between align-items-center">
                        <label className="d-flex">
                          <input type="checkbox" name="brand" value={value.url} checked={filter.brand.includes(value.url)} onChange={(e) => {
                            var brand = filter.brand;
                            var check = Array.isArray(brand) ? brand.findIndex((e) => e == value.url) : -1;
                            if (check < 0) {
                              brand.push(value.url)
                            } else {
                              brand.splice(check, 1)
                            }

                            setFilter({ ...filter, brand: brand, immediately: false, })
                          }}></input>
                          <div className="me-3">
                            <span>{value.nama}</span>
                          </div>
                        </label>
                        <div className="total">{value.total}</div>
                      </div>
                    ))}
                  </div>
                  <hr></hr>
                  <div className="wrapper-filter">
                    <div className="title">Category</div>
                    {categoryProduct.map((value, index) => (
                      <div className="item-filter d-flex justify-content-between align-items-center">
                        <label className="d-flex">
                          <input type="checkbox" name="category" value={value.url} checked={filter.category.includes(value.url)} onChange={(e) => {
                            var category = filter.category;
                            var check = Array.isArray(category) ? category.findIndex((e) => e == value.url) : -1;
                            if (check < 0) {
                              category.push(value.url)
                            } else {
                              category.splice(check, 1)
                            }

                            setFilter({ ...filter, category: category, immediately: false })
                          }}></input>
                          <div className="me-3">
                            <span>{value.nama}</span>
                          </div>
                        </label>
                        <div className="total">{value.total}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-9 col-12">
              {isDesktopScreen ?
                <div className="d-flex justify-content-end align-items-center">
                  <div className="text me-2">Sort By:</div>
                  <div className="" style={{ width: "250px" }}>
                    <Select
                      options={listFilter}
                      defaultValue={listFilter[0]}
                      placeholder="Filter"
                      className="basic-single"
                      classNamePrefix="select"
                      styles={colourStyles}
                      value={sortFilter}
                      isSearchable={false}
                      onChange={e => {
                        setSortFilter(e)
                        setFilter({ ...filter, sort: e.value, page: 1, immediately: true })
                      }}></Select>
                  </div>
                </div> : ''}
              <div className="list-item  mt-4 row">
                {productList.length > 0 ?
                  productList.map((value, index) => (
                    <div className="col-md-4 col-6">
                      <CardProduct
                        key={`listProduct${index}`}
                        discount={value.satuan_promo}
                        category={value.category}
                        name={value.nama}
                        image={imageUrl.product(value.gambar)}
                        url={`${value.url}${itemPromo.exist ? '?_a='+encodeURIComponent(itemPromo.hash) : ''}`}
                        rating={value.rating}
                        review={value.total_review}
                        originalPrice={value.harga}
                        discountPrice={value.harga_promo}></CardProduct>
                    </div>
                  ))
                  : ''}

                {onPageLoad ?
                  ['', '', '', '', '', '', '', '', ''].map((e, i) => (
                    <div className="col-md-4 col-6" key={`skeleton-producy${i}`}>
                      <SekeletonCardProduct></SekeletonCardProduct>
                    </div>
                  ))
                  : ''}
              </div>

              {!onPageLoad && productList.length == 0 ?
                <div className="text-center my-5">
                  <img src={productEmpty} height={100}
                    alt="empty-review"></img>
                  <div style={{ color: '#9A9A9A', fontSize: '1rem' }} className="mt-4">Product doesn’t exist</div>
                </div>
                : ''}
              {!onPageLoad && pagination.lastPage && pagination.currentPage != pagination.lastPage ?
                <div className="text-center">
                  <button type="button" style={{
                    padding: "8px 16px",
                    border: '1px solid #979797'
                  }} className="btn" onClick={(e) => {
                    getList(pagination.currentPage + 1)
                  }}>Load More</button>
                </div>
                : ''}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
