import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import Maps from "../components/element/Maps";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import imgContact from "../assets/img/img-contact-us.png";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { post_SaveMessage } from "../actions";
import ReactInputMask from "react-input-mask";
import MetaTags from "../helpers/MetaTags";
import { NavAbouts } from "../components/element/NavAboutus";
import Analytics from "../helpers/Analytics";
export function ContactUs() {
  const [myForm, setForm] = useState({
    nama: '',
    no_hp: '',
    email: '',
    pesan: ''
  })
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '-6.175237678515803,106.82712676006798',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })
  const [onSubmit, setOnSubmit] = useState(false)

  const mySwal = withReactContent(Swal);

  const onSaveData = (e) => {
    e.preventDefault();
    setOnSubmit(true)
    post_SaveMessage(myForm, (res) => {
      setOnSubmit(false)
      setForm({
        nama: '',
        no_hp: '',
        email: '',
        pesan: ''
      })
      mySwal.fire({
        title: "Message Sent!",
        text: "We will contact you immediately",
        icon: "success",
        confirmButtonColor: '#1f1f23'
      })
    }, () => {
      mySwal.fire({
        title: "ERROR!",
        text: "Someting wrong, please try again later",
        icon: "error",
        confirmButtonColor: '#1f1f23'
      })
    })
  }

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    // mySwal.fire({
    //   title: "Message Sent!",
    //   text: "We will contact you immediately",
    //   icon: "success",
    //   confirmButtonColor: '#1f1f23'
    // })
  }, [])
  return (
    <>
      <MetaTags title={ 'Contact Us - drmo store'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <Nav active="home"></Nav>
      <Analytics title={ 'Contact Us - drmo store'}></Analytics>
      <div className="container">
        <div className="section section-information" id="">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Contact Us
              </li>
            </ol>
          </nav>

          <div className="row mt-5">
            <div className="col-12 col-md-3">
            <NavAbouts></NavAbouts>
            </div>
            <div className="col-12 col-md-9">
              <div className="title-section">Contact Us</div>
              <div className="row mt-4">
                <div className="col-12 col-lg-6 mb-4">
                  <Maps coordinate={metaData.coordinate}></Maps>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                  <form onSubmit={onSaveData}>
                    <div className="form-group mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                        value={myForm.nama}
                        required
                        onChange={(e) => setForm({ ...myForm, nama: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label>Phone Number</label>
                      <ReactInputMask mask="999999999999999" required={true} value={myForm.no_hp} maskChar="" className="form-control" placeholder="Enter phone number" name="no_hp" onChange={e => setForm({ ...myForm, no_hp: e.target.value })}>
                      </ReactInputMask>
                      {/* <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        placeholder="Enter phone number"
                        required
                        onChange={(e) => setForm({ ...myForm, no_hp: e.target.value })}
                      /> */}
                    </div>
                    <div className="form-group mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={(e) => setForm({ ...myForm, email: e.target.value })}
                        className="form-control"
                        placeholder="Enter your email"
                        value={myForm.email}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label>Message</label>
                      <textarea
                        name="message"
                        className="form-control"
                        placeholder="Enter your message"
                        value={myForm.pesan}
                        onChange={(e) => setForm({ ...myForm, pesan: e.target.value })}
                        required></textarea>
                    </div>
                    <button type="submit" disabled={onSubmit} className="btn-black text-center">{onSubmit ? "Sending..." : 'Send Message'}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
