import React, { useEffect, useState } from "react";
import iconAlamat from "../assets/img/icon/icon-alamat.svg";
import iconPhone from "../assets/img/icon/icon-phone.svg";
import iconMobile from "../assets/img/icon/icon-mobile.svg";
import iconEmail from "../assets/img/icon/icon-email.svg";
import iconFacebook from "../assets/img/icon/icon-facebook.svg";
import iconTwitter from "../assets/img/icon/icon-twitter.svg";
import iconInstagram from "../assets/img/icon/icon-instagram.svg";
import iconYoutube from "../assets/img/icon/icon-youtube.svg";
import logo from "../assets/img/logo-putih.png";

import "../assets/scss/footer/footer.css";
import { Link } from "react-router-dom";
export function Footer() {
  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa:  ''
  })

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({...metaData, ...(JSON.parse(meta))});
        clearInterval(intervalCheckMeta);
      }
    }, 20)
  }, [])

  const checkUrl = (url) => {
    var urlFix = url;
    if (!url.includes('https://') && !url.includes('http://')) {
      urlFix = `https://${url}`
    } else if (url.includes('http')) {
      urlFix = url.replace('http://', 'https://');
    }
    return urlFix
  }
  return (
    <div>
      <footer className="footer">
        <div className="wrapper-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-12">
                <img src={logo} alt={logo} className="icon-brand"></img>
                <div className="desc">
                  {metaData.text_footer}
                </div>
              </div>
              {/* <div className="col-md-3 col-12 offset-md-2">
                <div className="title">Pelanggan</div>
                <ul className="link">
                  <li>Pelayanan Pelanggan</li>
                  <li>Pesanan dan Pengembalian</li>
                  <li>Pengiriman dan Pengiriman</li>
                  <li>Pembayaran</li>
                  <li>Garansi</li>
                </ul>
              </div> */}
              <div className="col-md-3 col-12 offset-md-1">
                <ul className="about-brand">
                  <li>
                    <div className="">
                      <img
                        src={iconAlamat}
                        alt={iconAlamat}
                        className="icon"></img>
                    </div>

                    <span>
                      {metaData.address}
                    </span>
                  </li>
                  <li>
                    <img src={iconPhone} alt={iconPhone} className="icon"></img>
                    <span>
                      Phone : <span className="value"> {metaData.phone}</span>
                    </span>
                  </li>
                  <li>
                    <img
                      src={iconMobile}
                      alt={iconMobile}
                      className="icon"></img>
                    <span>
                      Mobile : <span className="value">{metaData.phone_number}</span>
                    </span>
                  </li>
                  <li>
                    <img src={iconEmail} alt={iconEmail} className="icon"></img>
                    <span>
                      E-mail : <span className="value">{metaData.email}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 offset-md-1">
                <div className="title">Information</div>
                <ul className="link">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to="/warranty">Warranty</Link>
                  </li>
                </ul>
              </div>
            </div>
            <hr></hr>
            <div className="row align-items-center mt-4">
              <div className="col-12 col-md-4">
                <div className="copyright">
                  ©️ 2023 drmostore. All Rights Reserved
                </div>
              </div>
              <div className="col-md-8 col-12 text-start text-md-end">
                <ul className="sosmed">
                  {metaData.facebook_url ? <li>
                    <a target="_blank" href={checkUrl(metaData.facebook_url)}>
                      <img src={iconFacebook} alt={iconFacebook}></img>
                    </a>
                  </li>
                  : ''}
                  {metaData.twitter_url ?
                  <li>
                    <a target="_blank" href={checkUrl(metaData.twitter_url)}>
                      <img src={iconTwitter} alt={iconTwitter}></img>
                    </a>
                  </li>
                  : ''}
                  {metaData.instagram_url ?
                  <li>
                    <a target="_blank" href={checkUrl(metaData.instagram_url)}>
                      <img src={iconInstagram} alt={iconInstagram}></img>
                    </a>
                  </li>
                  : '' }
                  {metaData.youtube_url ?
                  <li>
                    <a target="_blank" href={checkUrl(metaData.youtube_url)}>
                      <img src={iconYoutube} alt={iconYoutube}></img>
                    </a>
                  </li>
                  : '' }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
