import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import "react-multi-carousel/lib/styles.css";
import "../assets/scss/home/home.css";
import CardBlog from "../components/element/CardBlog";
import news4 from "../assets/img/news-4.png";
import { get_OtherContent } from "../actions";
import Skeleton from "react-loading-skeleton";
import MetaTags from "../helpers/MetaTags";
import { NavAbouts } from "../components/element/NavAboutus";
import Analytics from "../helpers/Analytics";

export function PrivacyPolicy() {
  const [pageLoad, setPageLoad] = useState(true);
  const [content, setContent] = useState({
    title: '',
    content: "",
  })

  const [metaData, setMetaData] = useState({
    website_name: '',
    website_desc: '',
    website_keyword: '',
    address: '',
    phone: '',
    phone_number: '',
    email: '',
    coordinate: '-6.175237678515803,106.82712676006798',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    youtube_url: '',
    phone_wa: ''
  })

  useEffect(() => {
    var intervalCheckMeta;
    intervalCheckMeta = setInterval(() => {
      const meta = localStorage.getItem('_mta');
      if (meta) {
        setMetaData({ ...metaData, ...(JSON.parse(meta)) });
        clearInterval(intervalCheckMeta);
      }
    }, 20)
    setPageLoad(true)
    get_OtherContent('privacy-policy', (res) =>{
      const {data} = res; 
      setContent({...content, ...data})
      setPageLoad(false);
    })
  }, [])
  return (
    <>
      <MetaTags title={ 'Privacy Policy - drmo store'} url={window.location.href} description={metaData.website_desc} keyword={metaData.website_keyword} site_nime={metaData.website_name}></MetaTags>
      <Nav active="home"></Nav>
      <Analytics title={ 'Privacy Policy - drmo store'}></Analytics>
      <div className="container">
        <div className="section section-information" id="">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </li>
            </ol>
          </nav>

          <div className="row mt-5">
            <div className="col-md-3 col-12">
            <NavAbouts></NavAbouts>
            </div>
            <div className="col-12 col-md-9">
              <div className="title-section">Privacy Policy</div>
              {pageLoad ? 
              ['', '', ''].map((value, index) => (
                <div className="mt-3">
                  <Skeleton width={'100%'} height={'10'} count={5}></Skeleton>
                  <Skeleton width={'40%'} height={'10'}></Skeleton>
                </div>
              ))
              : <>
              <div className="mt-2" dangerouslySetInnerHTML={{ __html: content.content }}></div>
              </>}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
