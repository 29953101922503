import React from "react";

import news1 from "../../assets/img/news-1.png";
import news2 from "../../assets/img/news-2.png";
import news3 from "../../assets/img/news-3.png";
import news4 from "../../assets/img/news-4.png";
import news5 from "../../assets/img/news-5.png";
import news6 from "../../assets/img/news-6.png";
import seeAll from "../../assets/img/see-all.png";
import "../../assets/scss/home/home.css";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";
import { formatDate } from "../../helpers";

export function News({ list, loading }) {
  const navigate = useNavigate();

  const goToBlog = (url) => {
    if (url) {
      navigate(`/blog/${url}`)
    }
  }
  return (
    <>
      {list.length == 5 || loading ?
        <>
          <div className="container">
            <div className="" id="news">
              <div className="list-item d-flex">
                <div className={`row ${loading ? 'w-100' : ''}`}>
                  <div className="col-md-6 col-12">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        {loading ?
                          <div className="item-news">
                            <Skeleton width={'100%'} height={300}></Skeleton>
                          </div>
                          :
                          <Link to={`/blog/${list[0].url}`} style={{ color: 'unset' }}>
                            <div className="item-news">
                              <div className="category">{list[0].kategori}</div>
                              <div className="info-item">
                                <div className="title">{list[0].judul}</div>
                                <div className="date">{formatDate(list[0].created_at)}</div>
                              </div>
                              <img src={imageUrl.blog(list[0].gambar)} alt={imageUrl.blog(list[0].gambar)} className=""></img>
                            </div>
                          </Link>
                        }
                      </div>
                      <div className="col-md-6 col-12">
                        {loading ?
                          <div className="item-news">
                            <Skeleton width={'100%'} height={300}></Skeleton>
                          </div>
                          :
                          <Link to={`/blog/${list[1].url}`} style={{ color: 'unset' }}>
                            <div className="item-news">
                              <div className="category">{list[1].kategori}</div>
                              <div className="info-item">
                                <div className="title">{list[1].judul}</div>
                                <div className="date">{formatDate(list[1].created_at)}</div>
                              </div>
                              <img src={imageUrl.blog(list[1].gambar)} alt={imageUrl.blog(list[1].gambar)} className=""></img>
                            </div>
                          </Link>
                        }
                      </div>
                    </div>
                    {loading ?
                      <div className="item-news">
                        <Skeleton width={'100%'} height={300}></Skeleton>
                      </div>
                      :
                      <Link to={`/blog/${list[2].url}`} style={{ color: 'unset' }}>
                        <div className="item-news">
                          <div className="category">{list[2].kategori}</div>
                          <div className="info-item">
                            <div className="title">{list[2].judul}</div>
                            <div className="date">{formatDate(list[2].created_at)}</div>
                          </div>
                          <img src={imageUrl.blog(list[2].gambar)} alt={imageUrl.blog(list[2].gambar)} className=""></img>
                        </div>
                      </Link>
                    }

                  </div>
                  <div className="col-md-6 col-12">
                    {loading ?
                      <div className="item-news">
                        <Skeleton width={'100%'} height={300}></Skeleton>
                      </div>
                      :
                      <Link to={`/blog/${list[3].url}`} style={{ color: 'unset' }}>
                        <div className="item-news">
                          <div className="category">{list[3].kategori}</div>
                          <div className="info-item">
                            <div className="title">{list[3].judul}</div>
                            <div className="date">{formatDate(list[3].created_at)}</div>
                          </div>
                          <img src={imageUrl.blog(list[3].gambar)} alt={imageUrl.blog(list[3].gambar)} className=""></img>
                        </div>
                      </Link>
                    }
                    <div className="row">
                      <div className="col-md-6 col-12">
                        {loading ?
                          <div className="item-news">
                            <Skeleton width={'100%'} height={300}></Skeleton>
                          </div>
                          :
                          <Link to={`/blog/${list[4].url}`} style={{ color: 'unset' }}>
                            <div className="item-news">
                              <div className="category">{list[4].kategori}</div>
                              <div className="info-item">
                                <div className="title">{list[4].judul}</div>
                                <div className="date">{formatDate(list[4].created_at)}</div>
                              </div>
                              <img src={imageUrl.blog(list[4].gambar)} alt={imageUrl.blog(list[4].gambar)} className=""></img>
                            </div>
                          </Link>
                        }
                      </div>
                      <div className="col-md-6 col-12">
                        {loading ?
                          <div className="item-news">
                            <Skeleton width={'100%'} height={300}></Skeleton>
                          </div>
                          :
                          <div className="item-news">
                            <Link to="/blog" className="see-all">See All</Link>
                            <img src={seeAll} alt={seeAll} className=""></img>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        : ''}
    </>
  );
}
